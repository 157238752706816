import { MapsHomeWork, Edit } from "@mui/icons-material";
import { Box, Card, CardContent, Grid, Typography, InputLabel, Button } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { delDataObjects } from "../../../../API";
import { IObject } from "../../../../database/types";
import { DeleteModal } from "../../../../layout/components/DeleteModal";
import { DELETE_MODAL } from "../../../../layout/constants";
import { ListOfOwners } from "./ListOfOwners";
import { OwnerModal } from "./OwnerModal";

export const ObjectCard = ({ object }: { object: IObject }) => {
    const [open, setOpen] = React.useState(false);
    const handleDeleteObject = () => {
        setOpen(!open);
    };

    return (
        <>
            <DeleteModal open={open} setOpen={setOpen} id={object.id} item={DELETE_MODAL.OBJECT} />
            <Box paddingBottom={"1.5rem"}>
                <Card>
                    <CardContent>
                        <Typography
                            component="div"
                            variant="h5"
                            style={{ fontSize: "1.5em", padding: "0 0 0.3em 0" }}
                        >
                            BUDOVA
                        </Typography>
                        <Grid container spacing={1} paddingBottom={"1.5rem"}>
                            <Grid
                                item
                                lg={1}
                                md={1}
                                sm={1}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <MapsHomeWork style={{ fontSize: "2em" }} />
                            </Grid>
                            <Grid
                                item
                                lg={11}
                                md={11}
                                sm={11}
                                style={{
                                    paddingLeft: 0,
                                }}
                            >
                                <Typography
                                    component="div"
                                    variant="h6"
                                    style={{
                                        paddingLeft: "1em",
                                    }}
                                >
                                    {object.city ? `${object.city}` : ``}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Box>
                            <Grid container>
                                <Grid item lg={8} md={8} sm={12}>
                                    <Grid container spacing={1} paddingBottom={"1rem"}>
                                        <Grid item xl={4} sm={4} xs={12}>
                                            <InputLabel>Adresa:</InputLabel>
                                        </Grid>
                                        <Grid item xl={8} sm={8} xs={12}>
                                            <Typography component="div" variant="body1">
                                                {object.address ? `${object.address}` : ``}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={4} sm={4} xs={12}>
                                            <InputLabel>PSČ:</InputLabel>
                                        </Grid>
                                        <Grid item xl={8} sm={8} xs={12}>
                                            <Typography component="div" variant="body1">
                                                {object.zipcode ? `${object.zipcode}` : ``}
                                            </Typography>
                                        </Grid>
                                        <Grid item xl={4} sm={4} xs={12}>
                                            <InputLabel>Počet vlastníků:</InputLabel>
                                        </Grid>
                                        <Grid item xl={8} sm={8} xs={12}>
                                            <Typography component="div" variant="body1">
                                                {object.owners.length > 0
                                                    ? `${object.owners.length}`
                                                    : `?`}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid
                                    item
                                    lg={4}
                                    md={4}
                                    sm={12}
                                    style={{
                                        width: "100%",

                                        backgroundColor: "#c2c2c2",

                                        padding: "1em",
                                    }}
                                >
                                    <Typography component="div" variant="body1">
                                        MAPA
                                    </Typography>
                                </Grid>
                                <Grid item lg={12} md={12} sm={12} style={{ width: "100%" }}>
                                    <ListOfOwners
                                        object={object}
                                        openOwnerModal={false}
                                        setOpenOwnerModal={undefined}
                                    />
                                </Grid>
                            </Grid>
                        </Box>
                        <Box justifyContent={"flex-end"} display="flex" paddingBottom={"1rem"}>
                            <Button
                                variant="outlined"
                                color="error"
                                onClick={handleDeleteObject}
                                style={{ marginRight: "1em" }}
                            >
                                Smazat
                            </Button>

                            <Button
                                startIcon={<Edit />}
                                variant="contained"
                                to={`/objekty/${object.id}/editovat`}
                                component={Link}
                            >
                                Upravit budovu
                            </Button>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};
