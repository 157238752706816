import { AppAction } from "../../global";
import { ICreateUserState, IUserState } from "./types";
import * as UserConstants from "../Users/constants"

const defaultStateUser: IUserState = {
    user: {
        id: null,
        name: "",
        email: "",
        token: "",
        allow_clients_ids: [],
        role: null
    }
}
export const userReducer = (state: IUserState = defaultStateUser, action: AppAction) => {
    switch (action.type) {
        case UserConstants.SET_USER_AS_LOGGED:
            return {
                ...action.user
            }
        case UserConstants.LOGOUT_USER:
            return {
                ...defaultStateUser.user
            }
        default:
            return state;
    }
}

const defaultStateCreateUser: ICreateUserState = {
    editor: {
        id: null,
        name: "",
        email: "",
        password: "",
        allow_clients_ids: [],
        role: null
    }
}
export const createUserReducer = (state: ICreateUserState = defaultStateCreateUser, action: AppAction) => {
    switch (action.type) {
        case UserConstants.SET_USER_AS_EDITED:
            return {
                ...state,
                editor: action.user

            };
        case UserConstants.EDIT_USER:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.usrKey]: action.value
                }
            };
        case UserConstants.RESET_USER_EDITOR:
            return {
                ...state,
                editor: {
                    ...defaultStateCreateUser.editor
                }
            };
        case UserConstants.CHANGE_SELECTED:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    allow_clients_ids: action.selected
                }
            }
        default:
            return state;
    }
}