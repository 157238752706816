import { Add, Build } from "@mui/icons-material";
import {
    AppBar,
    Tabs,
    Tab,
    Box,
    Card,
    CardContent,
    Grid,
    Typography,
    Button,
    Accordion,
    AccordionSummary,
    AccordionDetails,
    InputLabel,
    ListItem,
    Avatar,
    Divider,
    List,
    ListItemAvatar,
    ListItemText,
} from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GlobalState } from "../../../global";
import {
    getClientById,
    getClientsBuildings,
    getClientsContracts,
    getClientsFiles,
    getServiceNameById,
} from "../selector";
import { IContract } from "../types";

import { IObject, IOperation } from "../../../database/types";
import { resetNoteEditor, resetObjectOwnerEditor, setClientAsEdited } from "../actions";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { useNavigate } from "react-router-dom";
import { delDataContracts, delDataObjects } from "../../../API";
import { MapsHomeWork, Edit, AccountBox } from "@mui/icons-material/";
import { ListOfOwners } from "./objects/ListOfOwners";
import { ObjectCard } from "./objects/ObjectCard";
import { ContractDetail } from "./contracts/ContractDetail";
import NoteDetail from "./notes/NoteDetail";
import { NoteModal } from "./notes/NoteModal";
import FileDetail from "./files/FilesDetail";
import { FileModal } from "./files/FileModal";
import FilesTable from "./files/FilesTable";

const ClientDetail = () => {
    const params = useParams();
    const oneClient = useSelector(getClientById(Number(params.id)));
    const contracts = useSelector(getClientsContracts(Number(params.id)));
    const notes = useSelector((state: GlobalState) => state.api.notes);
    const USER = useSelector((state: GlobalState) => state.logged.user);

    const BUILDINGS = useSelector(getClientsBuildings(Number(oneClient.id)));
    const FILES = useSelector(getClientsFiles(Number(oneClient.id)));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    dispatch(setClientAsEdited(Number(params.id)));
    const [APP_BAR, setAppBar] = useState(0);
    const [openNote, setOpenNote] = React.useState(false);
    const [openFile, setOpenFile] = React.useState(false);
    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                {/*CLIENT DETAIL*/}
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} paddingBottom={"0.2em"}>
                        <Card style={{ backgroundColor: "#28a745", color: "#fff" }}>
                            <CardContent>
                                <Box>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Grid container spacing={1} paddingLeft={"1em"}>
                                                <Grid
                                                    item
                                                    lg={2}
                                                    md={4}
                                                    sm={4}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        flexDirection: "column",

                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    <AccountBox
                                                        style={{
                                                            padding: 0,
                                                            fontSize: "6em",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={8}
                                                    sm={8}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        flexDirection: "column",
                                                        whiteSpace: "nowrap",
                                                        paddingLeft: "2em",
                                                    }}
                                                >
                                                    <Typography component="div" variant="h5">
                                                        {oneClient.name ? `${oneClient.name} ` : ``}
                                                        {oneClient.surname
                                                            ? `${oneClient.surname}`
                                                            : ``}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={4} sm={4} xs={12}>
                                                    <InputLabel style={{ color: "#eaeff1" }}>
                                                        Adresa:
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item xl={8} sm={8} xs={12}>
                                                    <Typography component="div" variant="body1">
                                                        {oneClient.address
                                                            ? `${oneClient.address}`
                                                            : ``}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={4} sm={4} xs={12}></Grid>
                                                <Grid item xl={8} sm={8} xs={12}>
                                                    <Typography component="div" variant="body1">
                                                        {oneClient.city && oneClient.zipcode
                                                            ? `${oneClient.city}, ${oneClient.zipcode}`
                                                            : ``}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={4} sm={4} xs={12}>
                                                    <InputLabel style={{ color: "#eaeff1" }}>
                                                        Telefon:
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item xl={8} sm={8} xs={12}>
                                                    <Typography component="div" variant="body1">
                                                        {oneClient.phone &&
                                                            !(oneClient.phone.length > 9)
                                                            ? `${oneClient.phone.slice(
                                                                0,
                                                                3
                                                            )} ${oneClient.phone.slice(
                                                                3,
                                                                6
                                                            )} ${oneClient.phone.slice(6, 9)}`
                                                            : ``}
                                                    </Typography>
                                                </Grid>
                                                <Grid item xl={4} sm={4} xs={12}>
                                                    <InputLabel style={{ color: "#eaeff1" }}>
                                                        E-Mail:
                                                    </InputLabel>
                                                </Grid>
                                                <Grid item xl={8} sm={8} xs={12}>
                                                    <Typography component="div" variant="body1">
                                                        {oneClient.email
                                                            ? `${oneClient.email}`
                                                            : ``}
                                                    </Typography>
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={12}
                                                    md={12}
                                                    sm={12}
                                                    style={{
                                                        padding: "1em 0.3em",
                                                    }}
                                                >
                                                    <Button
                                                        style={{
                                                            fontWeight: 500,
                                                            color: "#eaeff1",
                                                        }}
                                                        to={`/klienti/${oneClient.id}/editovat`}
                                                        color="inherit"
                                                        component={Link}
                                                        variant="outlined"
                                                    >
                                                        Upravit klienta
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item lg={6} md={6} sm={12}>
                                            <Typography
                                                component="div"
                                                variant="body1"
                                            ></Typography>
                                        </Grid>
                                        {contracts && contracts.length > 0 ? (
                                            <>
                                                <Grid
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                    }}
                                                    paddingLeft={"1em"}
                                                >
                                                    <Grid
                                                        item
                                                        lg={10}
                                                        md={10}
                                                        sm={10}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            variant="h6"
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                        >
                                                            ZAKÁZKY:
                                                        </Typography>
                                                        <InputLabel
                                                            style={{
                                                                paddingLeft: "1em",
                                                                color: "#eaeff1",
                                                            }}
                                                        >
                                                            {`${contracts.length}`}
                                                        </InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        {BUILDINGS.length > 0 ? (
                                            <>
                                                <Grid
                                                    container
                                                    sx={{
                                                        display: "flex",
                                                    }}
                                                    paddingLeft={"1em"}
                                                >
                                                    <Grid
                                                        item
                                                        lg={10}
                                                        md={10}
                                                        sm={10}
                                                        style={{
                                                            display: "flex",
                                                            flexDirection: "row",
                                                            alignItems: "center",
                                                        }}
                                                    >
                                                        <Typography
                                                            component="div"
                                                            variant="h6"
                                                            style={{
                                                                width: "100px",
                                                            }}
                                                        >
                                                            BUDOVY:
                                                        </Typography>
                                                        <InputLabel
                                                            style={{
                                                                paddingLeft: "1em",
                                                                color: "#eaeff1",
                                                            }}
                                                        >
                                                            {`${BUILDINGS.length}`}
                                                        </InputLabel>
                                                    </Grid>
                                                </Grid>
                                            </>
                                        ) : (
                                            ""
                                        )}
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
                {/*APPBAR*/}
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} paddingBottom={"1rem"}>
                        <Card>
                            <CardContent sx={{ p: 0 }}>
                                <AppBar
                                    component="div"
                                    position="static"
                                    elevation={1}
                                    sx={{ zIndex: 0 }}
                                    style={{ backgroundColor: "#28a745", color: "#fff" }}
                                >
                                    <Tabs value={APP_BAR} textColor="inherit">
                                        <Tab label="Zakázky" onClick={() => setAppBar(0)} />
                                        <Tab label="Poznámky" onClick={() => setAppBar(1)} />
                                        <Tab label="Soubory" onClick={() => setAppBar(2)} />
                                        {/*  <Tab label="Upomínky" /> */}
                                    </Tabs>
                                </AppBar>
                                <Box padding={"1em"}>
                                    <Grid container spacing={2}>
                                        <Grid item lg={12} md={12} sm={12}>
                                            <Box
                                                justifyContent={"flex-end"}
                                                display="flex"
                                                paddingBottom={"1em"}
                                            >
                                                {APP_BAR === 0 && (
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="contained"
                                                        to="/zakazky/vytvorit"
                                                        component={Link}
                                                        disabled={BUILDINGS.length === 0}
                                                    >
                                                        PŘIDAT ZAKÁZKU
                                                    </Button>
                                                )}
                                                {APP_BAR === 1 && (
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setOpenNote(true);
                                                            dispatch(
                                                                resetNoteEditor(USER, oneClient.id)
                                                            );
                                                        }}
                                                    >
                                                        PŘIDAT POZNÁMKU
                                                    </Button>
                                                )}
                                                {APP_BAR === 2 && (
                                                    <Button
                                                        startIcon={<Add />}
                                                        variant="contained"
                                                        onClick={() => {
                                                            setOpenFile(true);
                                                        }}
                                                    >
                                                        PŘIDAT SOUBOR
                                                    </Button>
                                                )}
                                            </Box>
                                        </Grid>
                                        {/*contracts*/}
                                        <Grid item lg={12} md={12} sm={12}>
                                            {contracts.length > 0 && APP_BAR === 0
                                                ? contracts.map((contract, i) => (
                                                    <ContractDetail
                                                        key={`contract_${i}`}
                                                        contract={contract}
                                                        index={i}
                                                    />
                                                ))
                                                : APP_BAR === 0 &&
                                                    oneClient.surname &&
                                                    oneClient.name
                                                    ? `${oneClient.name} ${oneClient.surname} nemá žádné zakázky`
                                                    : ``}
                                        </Grid>
                                        {/*notes*/}
                                        <NoteModal open={openNote} setOpen={setOpenNote} />

                                        <Grid
                                            container
                                            spacing={2}
                                            style={{ paddingLeft: "1.3em" }}
                                        >
                                            {notes && notes.length > 0 && APP_BAR === 1 ? (
                                                notes.map((note, i) => (
                                                    <>
                                                        {note.client_id == oneClient.id && (
                                                            <Grid
                                                                item
                                                                lg={3}
                                                                md={6}
                                                                sm={12}
                                                                key={`note_${i}`}
                                                            >
                                                                <NoteDetail
                                                                    note={note}
                                                                    openNote={openNote}
                                                                    setOpenNote={setOpenNote}
                                                                />
                                                            </Grid>
                                                        )}
                                                    </>
                                                ))
                                            ) : APP_BAR === 1 ? (
                                                <div
                                                    style={{ paddingLeft: "1em" }}
                                                >{`Klient nemá žádné poznámky`}</div>
                                            ) : (
                                                ``
                                            )}
                                        </Grid>

                                        {/*files*/}
                                        <FileModal open={openFile} setOpen={setOpenFile} />
                                        <Grid item lg={12} md={12} sm={12}>

                                            {FILES.length > 0 && APP_BAR === 2
                                                ? <FilesTable id={oneClient.id} />
                                                : ``}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                {/*OBJECTS*/}
                <Grid container spacing={2}>
                    <Grid item paddingBottom={"1em"} style={{ width: "100%" }}>
                        <Box justifyContent={"flex-end"} display="flex">
                            <Button
                                startIcon={<Add />}
                                variant="contained"
                                to="/objekty/vytvorit"
                                component={Link}
                            >
                                PŘIDAT BUDOVU
                            </Button>
                        </Box>
                    </Grid>
                </Grid>
                <Box>
                    {BUILDINGS.length > 0 ? (
                        BUILDINGS.map((obj, i) => <ObjectCard key={i} object={obj} />)
                    ) : (
                        <Box paddingBottom={"1.5rem"}>
                            <Card style={{ backgroundColor: "#37474f", color: "#fff" }}>
                                <CardContent>
                                    {`Pokud chcete přidat zakázku začněte tím, že přidáte budovu.`}
                                </CardContent>
                            </Card>
                        </Box>
                    )}
                </Box>
            </Box>
        </>
    );
};

export default ClientDetail;
