import { combineReducers } from "redux";
import { databaseReducer, apiReducer } from "./database/reducer";
import { clientReducer, contractReducer, fileReducer, objectReducer } from "./pages/Clients/reducer";
import { createUserReducer, userReducer } from "./pages/Users/reducer";
export const createAppReducer = () => {
    return combineReducers({

        static_db: databaseReducer,
        api: apiReducer,
        logged: userReducer,
        client: clientReducer,
        contract: contractReducer,
        object: objectReducer,
        user: createUserReducer,
        file: fileReducer,
    });
};