import {
    List,
    Divider,
    ListItem,
    ListItemAvatar,
    Avatar,
    ListItemText,
    Typography,
    Button,
    Grid,
} from "@mui/material";
import React, { useState } from "react";
import { IObject } from "../../../../database/types";
import { Edit, PersonRemove } from "@mui/icons-material";
import { Box } from "@mui/system";
import { OwnerModal } from "./OwnerModal";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
    removeClientFromOwnerEditor,
    resetObjectOwnerEditor,
    setClientToOwnerEditor,
} from "../../actions";
import { GlobalState } from "../../../../global";
import { useLocation } from "react-router-dom";

export const ListOfOwners = ({
    object,
    openOwnerModal,
    setOpenOwnerModal,
}: {
    object: IObject;
    openOwnerModal: boolean;
    setOpenOwnerModal: Function;
}) => {
    const OBJECT_OWNERS = object.owners;
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    const handleOpenEditor = () => {
        setOpenOwnerModal(!openOwnerModal);
    };
    const location = useLocation();
    const edited = location.pathname === `/objekty/${object.id}/editovat`;
    const create = location.pathname === `/objekty/vytvorit`;
    return (
        <>
            <Typography component="div" variant="h6" paddingBottom={"1rem"}>
                {`Vlastníci`}
            </Typography>
            {(create || edited) && (
                <Typography
                    component="div"
                    variant="body2"
                    paddingBottom={"1rem"}
                    style={{ color: "#ff9800" }}
                >
                    Po úpravě ,odebrání nebo přidání majitelů nezapomeňte kliknout na <b>ULOŽIT</b>!
                </Typography>
            )}
            <List
                sx={{
                    bgcolor: "background.paper",
                }}
            >
                <Grid container spacing={2}>
                    {OBJECT_OWNERS.map((owner, i) => (
                        <Grid item lg={3} md={6} sm={12} key={i}>
                            <OwnerModal
                                open={openOwnerModal}
                                setOpen={(state) => setOpenOwnerModal(state)}
                                id={i}
                            />

                            <div style={{ border: "1px solid #AFAFAF" }}>
                                <ListItem alignItems="flex-start">
                                    <ListItemAvatar>
                                        <Avatar alt={`${owner.surname} ${owner.name} `} src="/" />
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={
                                            owner.name || owner.surname
                                                ? `${owner.name} ${owner.surname}`
                                                : ``
                                        }
                                        secondary={
                                            <React.Fragment>
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {owner.address ? `${owner.address}` : ``}
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="body2"
                                                    color="text.primary"
                                                >
                                                    {owner.city ? `${owner.city}` : ``},{" "}
                                                    {owner.zipcode ? `${owner.zipcode}` : ``}
                                                </Typography>

                                                <br />
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="caption"
                                                    color="text.primary"
                                                >
                                                    {owner.phone ? `${owner.phone}` : ``}
                                                </Typography>
                                                <br />
                                                <Typography
                                                    sx={{ display: "inline" }}
                                                    component="span"
                                                    variant="caption"
                                                    color="text.primary"
                                                >
                                                    {owner.email ? `${owner.email}` : ``}
                                                </Typography>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                                {(edited || create) && (
                                    <Box
                                        sx={{ p: "1em" }}
                                        display={"flex"}
                                        justifyContent="flex-end"
                                    >
                                        {(edited || create) && i !== 0 && (
                                            <Button
                                                startIcon={<PersonRemove />}
                                                color="error"
                                                sx={{ mr: "0.5em" }}
                                                onClick={() => {
                                                    dispatch(
                                                        removeClientFromOwnerEditor(
                                                            edited ? owner.id : i
                                                        )
                                                    );
                                                }}
                                                variant="outlined"
                                            >
                                                ODEBRAT
                                            </Button>
                                        )}
                                        <Button
                                            startIcon={<Edit />}
                                            onClick={() => {
                                                handleOpenEditor();
                                                dispatch(
                                                    setClientToOwnerEditor(edited ? owner.id : i)
                                                );
                                            }}
                                            variant="contained"
                                        >
                                            OTEVŘÍT
                                        </Button>
                                    </Box>
                                )}
                            </div>
                        </Grid>
                    ))}
                </Grid>
            </List>
        </>
    );
};
