import { GridColDef, GridValueFormatterParams } from "@mui/x-data-grid";
const PLUGIN = "CLIENTS";
const PLUGIN2 = "CONTRACTS";
const PLUGIN3 = "OBJECTS";
const PLUGIN4 = "FILES";
export const EDIT_CLIENT = `${PLUGIN}_edit_client`;
export enum EDITOR {
    ID = "id",
    USER_ID = "user_id",
    EMAIL = "email",
    NAME = "name",
    SURNAME = "surname",
    PHONE = "phone",
    ADDRESS = "address",
    CITY = "city",
    ZIPCODE = "zipcode",

    CADASTRAL_TERRITORY = "cadastral_territory",
    NUMBER_CADASTRAL_TERRITORY = "number_cadastral_territory",
    PARCEL_NUMBER = "parcel_number",
}

export const SET_CLIENT_AS_EDITED = `${PLUGIN}_set_client_as_edited`;
export const RESET_EDITOR = `${PLUGIN}_reset_editor`;

export const EDIT_CONTRACT = `${PLUGIN}_edit_contract`;

export const OPTIONS_SET_ADD_BUILDING = `${PLUGIN}-options_set_add_building`;

export enum CONTRACT_EDITOR {
    ID = "id",
    BUILDING = "building",
    USER_ID = "user_id",
    SERVICE = "service",
    OPERATION = "operation",
    AGREED_PRICE = "agreedPrice",
    DEPOSIT = "deposit",
    COMMENT = "comment"
}

export enum OBJECT_EDITOR {
    ID = "id",
    CLIENT_ID = "client_id",
    ADDRESS = "address",
    CITY = "city",
    ZIPCODE = "zipcode",
    OWNERS = "owners",

    CADASTRAL_TERRITORY = "cadastral_territory",
    NUMBER_CADASTRAL_TERRITORY = "number_cadastral_territory",
    PARCEL_NUMBER = "parcel_number",
}

export const EDIT_OWNER_CLIENT = `${PLUGIN3}_edit_owner_client`
export enum OWNER_EDITOR {
    EMAIL = "email",
    NAME = "name",
    SURNAME = "surname",
    PHONE = "phone",
    ADDRESS = "address",
    CITY = "city",
    ZIPCODE = "zipcode"
}

export const EDIT_NOTE = `${PLUGIN}_edit_note`
export enum NOTE_EDITOR {
    NOTE_CONTENT = "note_content",
    NOTE_HEADLINE = "note_headline"
}

export const EDIT_FILE = `${PLUGIN4}_edit_file`
export enum FILE_EDITOR {
    FILE_NAME = "file_name",
}

export const SET_NOTE_AS_EDITED = `${PLUGIN}_set_note_as_edited`;
export const RESET_NOTE_EDITOR = `${PLUGIN}_reset_note_editor`;

export const SET_CONTRACT_AS_EDITED = `${PLUGIN2}_set_contract_as_edited`;
export const SET_CONTRACT_AS_EDITED_CREATE = `${PLUGIN2}_set_contract_as_edited_create`;
export const SET_CONTRACT_OPERATION_AS_EDITED = `${PLUGIN2}_set_contract_operation_as_edited`;
export const SET_CONTRACT_OPERATION_STATUS_AS_EDITED = `${PLUGIN2}_set_contract_operation_status_as_edited`;
export const RESET_CONTRACT_EDITOR = `${PLUGIN2}_reset_contract_editor`;

export const SET_OBJECT_AS_EDITED = `${PLUGIN3}_set_object_as_edited`;
export const SET_OBJECT_AS_EDITED_CREATE = `${PLUGIN3}_set_object_as_edited_create`;
export const EDIT_OBJECT = `${PLUGIN3}_edit_object`;
export const RESET_OBJECT_EDITOR = `${PLUGIN3}_reset_object_editor`;

export const SET_CLIENT_TO_OWNER_EDITOR = `${PLUGIN3}_set_client_to_owner_editor`;
export const RESET_OBJECT_OWNER_EDITOR = `${PLUGIN3}_reset_object_owner_editor`;
export const SET_OWNER_TO_OBJECT_EDITOR = `${PLUGIN3}_set_owner_to_object_editor`;
export const SET_NEW_OWNER_TO_OBJECT_EDITOR = `${PLUGIN3}_set_new_owner_to_object_editor`
export const REMOVE_OWNER_CLIENT = `${PLUGIN3}_remove_owner_from_object_editor`

export const SET_FILE_AS_EDITED = `${PLUGIN4}_set_file_as_edited`;
export const RESET_FILE_EDITOR = `${PLUGIN4}_reset_file_editor`;
////////////////////////////////////////////////////////////////////
export const COLUMNS: GridColDef[] = [
    { field: "id", headerName: "ID", width: 45, hideable: false },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "name", headerName: "Jméno", width: 130 },
    { field: "surname", headerName: "Příjmení", width: 130 },
    {
        field: "button",
        headerName: "Akce",
        width: 180,
        sortable: false,
        filterable: false,
        renderCell(params) {
            return params.value;
        },
    },
    { field: "phone", headerName: "Telefon", width: 140 },
    { field: "address", headerName: "Adresa", width: 200 },
    { field: "city", headerName: "Město", width: 200 },
    { field: "zipcode", headerName: "PSČ", width: 65 },
    { field: "userOwner", headerName: "Patří uživateli", width: 100 },
    // {
    //     field: "timeStamp",
    //     headerName: "Poslední měření",
    //     width: 150,
    //     valueFormatter: (params: GridValueFormatterParams<Date>) => {
    //         if (params.value == null) {
    //             return "";
    //         }
    //         return params.value.toLocaleString();
    //     },
    // },

];

export const FILE_DATATABLE_COLUMNS: GridColDef[] = [
    { field: "name", headerName: "Název souboru", width: 800 },
    {
        field: "button",
        headerName: "Akce",
        width: 250,
        sortable: false,
        filterable: false,
        renderCell(params) {
            return params.value;
        },
    },
    // {
    //     field: "timeStamp",
    //     headerName: "Poslední měření",
    //     width: 150,
    //     valueFormatter: (params: GridValueFormatterParams<Date>) => {
    //         if (params.value == null) {
    //             return "";
    //         }
    //         return params.value.toLocaleString();
    //     },
    // },

];