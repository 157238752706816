import { gridClasses, csCZ } from "@mui/x-data-grid";
export const DATA_GRID_DISABLE_OUTLINED_CELL = {
    [`& .${gridClasses.cell}:focus, & .${gridClasses.cell}:focus-within`]: {
        outline: "none",
    },
    [`& .${gridClasses.columnHeader}:focus, & .${gridClasses.columnHeader}:focus-within`]:
    {
        outline: "none",
    },
};

export const locale = csCZ.components.MuiDataGrid.defaultProps.localeText

export enum DELETE_MODAL {
    OBJECT = "object",
    CONTRACT = "contract",
    CLIENT = "client"
}