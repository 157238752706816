import { Edit } from "@mui/icons-material";
import {
    Card,
    CardHeader,
    CardContent,
    Typography,
    CardActions,
    Box,
    Button,
    Divider,
    IconButton,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { delDataNotes } from "../../../../API";
import { setNoteAsEdited } from "../../actions";
import { INote } from "../../types";
import { NoteModal } from "./NoteModal";
const NoteDetail = ({
    openNote,
    setOpenNote,
    note,
}: {
    note: INote;
    openNote: boolean;
    setOpenNote: Function;
}) => {
    let currentTimestamp = new Date(note.updated_at);
    let date = new Intl.DateTimeFormat("cs-CZ", {}).format(currentTimestamp);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <>
            <Card>
                <CardHeader
                    subheader={
                        date ? (
                            <>
                                <Typography variant="h5" color="text.primary">
                                    {note.note_headline ? `${note.note_headline}` : ``}
                                </Typography>
                                {`${date}`}
                            </>
                        ) : (
                            ``
                        )
                    }
                />
                <CardContent>
                    <Typography variant="body1" color="text.secondary">
                        {note.note_content ? `${note.note_content}` : ``}
                    </Typography>
                </CardContent>
                <Box justifyContent={"flex-end"} display="flex">
                    <Divider />
                    <CardActions disableSpacing>
                        <IconButton>
                            <Edit
                                onClick={() => {
                                    setOpenNote(!openNote);
                                    dispatch(setNoteAsEdited(note.id));
                                }}
                            />
                        </IconButton>
                    </CardActions>
                </Box>
            </Card>
        </>
    );
};

export default NoteDetail;
