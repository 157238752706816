import * as React from "react";
import AppBar from "@mui/material/AppBar";

import Grid from "@mui/material/Grid";
import {AccountCircle,Logout } from "@mui/icons-material/";
import IconButton from "@mui/material/IconButton";
import Link from "@mui/material/Link";
import MenuIcon from "@mui/icons-material/Menu";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { logout } from "../../API";
import { GlobalState } from "../../global";

const lightColor = "rgba(255, 255, 255, 0.7)";

interface HeaderProps {
    onDrawerToggle: () => void;
}

export default function Header(props: HeaderProps) {
    const { onDrawerToggle } = props;
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const USER = useSelector((state: GlobalState) => state.logged.user);
    return (
        <React.Fragment>
            <AppBar style={{ backgroundColor: "#1d7f33" }} position="sticky" elevation={0}>
                <Toolbar>
                    <Grid container spacing={1} alignItems="center">
                        <Grid sx={{ display: { sm: "none", xs: "block" } }} item>
                            <IconButton
                                color="inherit"
                                aria-label="open drawer"
                                onClick={onDrawerToggle}
                                edge="start"
                            >
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item xs />
                        <Grid item>
                            <Link
                                href="#"
                                variant="body2"
                                sx={{
                                    textDecoration: "none",
                                    color: lightColor,
                                    "&:hover": {
                                        color: "common.white",
                                    },
                                }}
                                rel="noopener noreferrer"
                            >
                               {`${USER.name}`} 
                            </Link>
                        </Grid>
                        <Grid item>
                            <IconButton color="inherit" sx={{ p: 0.5 }}>
                                <AccountCircle />
                                
                            </IconButton>
                            <IconButton color="inherit" sx={{ ml:"0.5em",p: 0.5 }} onClick={()=>dispatch(logout())}>
                                <Logout/>
                            </IconButton>
                            
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
        </React.Fragment>
    );
}
