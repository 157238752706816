import { createSelector } from "@reduxjs/toolkit";
import { IObject } from "../../database/types";
import { GlobalState } from "../../global";

export const getClientById = (index: number) =>
    createSelector(
        (state: GlobalState) => state.api.clients,
        (list) => list.find((client) => Number(client.id) === index)
    );

export const getClientsContracts = (id: number) =>
    createSelector(
        (state: GlobalState) => state.api.contracts,
        (list) => list.filter((cont, i) => Number(cont.client_id) === id)
    );
export const getServiceNameById = (id: number) =>
    createSelector(
        (state: GlobalState) => state.static_db.services,
        (list) => list.find((service, i) => Number(service.id) === id ? service : service.name = "")

    );
export const getContractById = (index: number) =>
    createSelector(
        (state: GlobalState) => state.api.contracts,
        (list) => list.find((contract, i) => Number(contract.id) === index)
    );

export const getClientsBuildings = (id: number) =>
    createSelector(
        (state: GlobalState) => state.api.objects,
        (list) => list.filter((obj, i) => Number(obj.client_id) === id)
    );
export const getOneBuildingById = (id: number) =>
    createSelector(
        (state: GlobalState) => state.api.objects,
        (list) => list.find((obj) => Number(obj.id) === Number(id))
    );
export const getObjectById = (index: number) => createSelector(
    (state: GlobalState) => state.api.objects,
    (list) => list.find((obj, i) => Number(obj.client_id) === index)
);

export const getClientsFiles = (id: number) =>
    createSelector(
        (state: GlobalState) => state.api.files,
        (list) => list.filter((file, i) => Number(file.client_id) === id)
    );
    export const getUsersForOwner= () => 
    createSelector(
        (state: GlobalState) => state.api.users,
        (users) => {

            return users;
        }
    );