import { IStatic_dbState, IApiState } from './types';
import * as constants from './constants';
import { AppAction } from "../global";

const defaultDatabaseState: IStatic_dbState = {
    services: [],
    documentStatuses: {
        contractors: [],
        clients: []
    },
    roleTable: []
};
const defaultApiState: IApiState = {
    clients: [],
    contracts: [],
    objects: [],
    notes: [],
    users: [],
    files: [],
};

export const databaseReducer = (state: IStatic_dbState = defaultDatabaseState, action: AppAction) => {
    switch (action.type) {
        case constants.INIT_DATA_STORE:
            return {
                ...state,
                services: action.init.services,
                documentStatuses: action.init.documentStatuses,
                roleTable: action.init.roleTable
            }
        default:
            return state;
    }
}

export const apiReducer = (state: IApiState = defaultApiState, action: AppAction) => {
    switch (action.type) {
        case constants.INIT_API_DATA_STORE:
            return {
                ...state,
                users: action.initApi.users,
                clients: action.initApi.clients,
                contracts: action.initApi.contracts,
                objects: action.initApi.objects,
                notes: action.initApi.notes,
                files: action.initApi.files
            }

        case constants.DELETE_CLIENT:
            return {
                ...state,
                clients: state.clients
                    .filter((turn) => turn.id !== action.clientId)
            }
        case constants.SAVE_CLIENT:
            if (action.clientId === null) {
                return {
                    ...state,
                    clients: [...state.clients, action.client]
                };
            }
            return {
                ...state,
                clients: state.clients.map((client) =>
                    client.id === action.clientId ? action.client : client
                ),
            };

        case constants.DELETE_CONTRACT:
            return {
                ...state,
                contracts: state.contracts
                    .filter((cont) => cont.id !== action.contractId)
            }
        case constants.SAVE_CONTRACT:
            if (action.contractId === null) {
                return {
                    ...state,
                    contracts: [...state.contracts, action.contract]
                };
            }
            return {
                ...state,
                contracts: state.contracts.map((contract) =>
                    contract.id === action.contractId ? action.contract : contract
                ),
            };

        case constants.DELETE_OBJECT:
            return {
                ...state,
                objects: state.objects
                    .filter((object) => object.id !== action.objectId)
            }
        case constants.SAVE_OBJECT:
            if (action.objectId === null) {
                return {
                    ...state,
                    objects: [...state.objects, action.object]
                };
            }
            return {
                ...state,
                objects: state.objects.map((object) =>
                    object.id === action.objectId ? action.object : object
                ),
            };

        case constants.DELETE_NOTE:
            return {
                ...state,
                notes: state.notes
                    .filter((note) => note.id !== action.noteId)
            }
        case constants.SAVE_NOTE:
            if (action.noteId === null) {
                return {
                    ...state,
                    notes: [...state.notes, action.note]
                };
            }
            return {
                ...state,
                notes: state.notes.map((note) =>
                    note.id === action.noteId ? action.note : note
                ),
            };


        case constants.DELETE_USER:
            return {
                ...state,
                users: state.users
                    .filter((user) => user.id !== action.userId)
            }
        case constants.SAVE_USER:
            if (action.userId === null) {
                return {
                    ...state,
                    users: [...state.users, action.user]
                };
            }
            return {
                ...state,
                users: state.users.map((user) =>
                    user.id === action.userId ? action.user : user
                ),
            };
        default:
            return state;
    }
}