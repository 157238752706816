import { AccountBox, AccountCircle } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Grid, InputLabel, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { GlobalState } from "../../../global";
import { getRoleById, getUserById } from "../selector";

export const UserDetail = () => {
    const params = useParams();
    const oneUser = useSelector(getUserById(Number(params.id)));
    const role = useSelector(getRoleById(Number(oneUser.role)));
    const USER = useSelector((state: GlobalState) => state.logged.user);
    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                {/*CLIENT DETAIL*/}
                <Grid container spacing={2}>
                    <Grid item lg={12} md={12} sm={12} paddingBottom={"0.2em"}>
                        <Card style={{ backgroundColor: "#28a745", color: "#fff" }}>
                            <CardContent>
                                <Box>
                                    <Grid container>
                                        <Grid item lg={6} md={6} sm={12}>
                                            <Grid container spacing={1} paddingLeft={"1em"}>
                                                <Grid
                                                    item
                                                    lg={2}
                                                    md={4}
                                                    sm={4}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-start",
                                                        flexDirection: "column",

                                                        paddingLeft: 0,
                                                    }}
                                                >
                                                    <AccountCircle
                                                        style={{
                                                            padding: 0,
                                                            fontSize: "6em",
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid
                                                    item
                                                    lg={10}
                                                    md={8}
                                                    sm={8}
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "center",
                                                        flexDirection: "column",
                                                        whiteSpace: "nowrap",
                                                        paddingLeft: "2em",
                                                    }}
                                                >
                                                    <Typography component="div" variant="h5">
                                                        {oneUser.name ? `${oneUser.name}` : ``}
                                                    </Typography>
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <Box marginTop={"1.5rem"}>
                    <Card style={{ backgroundColor: "#fff" }}>
                        <CardContent>
                            <Grid
                                item
                                lg={10}
                                md={8}
                                sm={8}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    whiteSpace: "nowrap",
                                }}
                            >
                                <Typography component="div" variant="h5">
                                    {role.name ? `${role.name}` : ``}
                                </Typography>
                            </Grid>
                            <Grid
                                item
                                lg={10}
                                md={8}
                                sm={8}
                                style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    flexDirection: "column",
                                    whiteSpace: "nowrap",
                                }}
                                paddingBottom={"0.2em"}
                            >
                                <Typography component="div" variant="h6">
                                    {oneUser.email ? `${oneUser.email}` : ``}
                                </Typography>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item lg={12} md={12} sm={12} padding={"0.2em"}>
                                    <Button
                                        to={`/uzivatele/${oneUser.id}/editovat`}
                                        component={Link}
                                        variant="contained"
                                    >
                                        Upravit
                                    </Button>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </Box>
            </Box>
        </>
    );
};
