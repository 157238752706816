import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import {
    delDataClients,
    delDataContracts,
    delDataNotes,
    delDataObjects,
    getInitApiState,
} from "../../API";
import { GlobalState, IDeleteModalProps } from "../../global";
import { DELETE_MODAL } from "../constants";

export const DeleteModal = ({ open, setOpen, item, id }: IDeleteModalProps) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const client_ID = id;

    const itemName = (item: string) => {
        if (DELETE_MODAL.OBJECT === item) {
            return `BUDOVU`;
        } else if (DELETE_MODAL.CLIENT === item) {
            return `KLIENTA`;
        } else if (DELETE_MODAL.CONTRACT === item) {
            return `ZAKÁZKU`;
        }
    };
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            scroll={"paper"}
            maxWidth={"xl"}
        >
            <DialogTitle id="scroll-dialog-title">UPOZORNĚNÍ</DialogTitle>
            <DialogContent dividers>
                <Typography>Všechna data budou nenávratně odstraněna z databáze!</Typography>
                <br />
                <Typography>Opravdu chcete smazat {itemName(item)}?</Typography>
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    ZRUŠIT
                </Button>
                <Button
                    variant="contained"
                    color="error"
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                        if (DELETE_MODAL.CLIENT === item) {
                            dispatch(delDataClients(client_ID));
                            dispatch(getInitApiState());
                        }
                        if (DELETE_MODAL.OBJECT === item) {
                            dispatch(delDataObjects(client_ID));
                        }
                        if (DELETE_MODAL.CONTRACT === item) {
                            dispatch(delDataContracts(client_ID));
                        }
                        setOpen(!open);
                    }}
                >
                    SMAZAT
                </Button>
            </DialogActions>
        </Dialog>
    );
};
