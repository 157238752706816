import { storeInitState, storeInitApiState, deleteClient, onSaveClient, onSaveContract, deleteContract, onSaveObject, 
    deleteObject, onSaveNote, deleteNote, onSaveUser, deleteUser } from "./database/actions";
import { Dispatch } from "redux";
import { GlobalState } from "./global";
import { IClient, IContract, INote } from "./pages/Clients/types";
import { IObject } from "./database/types";
import { LOGOUT_USER } from "./pages/Users/constants";
import { setObjectAsEditedCreate } from "./pages/Clients/actions";
import { ICreateUser } from "./pages/Users/types";

export const server = `https://backend.renami.cz/api`;
// export const server = `http://127.0.0.1:8000/api`;

export const getInitStatic_dbState = () => async (dispatch: Dispatch) => {
    try {
        const res = await fetch(
            "./db.json",
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!res.ok) {
            throw Error(
                "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
            );
        }
        const data = await res.text();
        dispatch(storeInitState(JSON.parse(data)));
        console.log(JSON.parse(data))
    } catch (error) {
        console.log(error);
    }
};

export const getInitApiState = () => async (dispatch: Dispatch) => {

    try {
        const res = await fetch(
            `${server}/db`,
            {
                method: "GET",
                headers: {
                    'Authorization': `Bearer ${sessionStorage.token}`,
                    "Content-Type": "application/json",
                },
            }
        );
        if (!res.ok) {
            throw Error(
                "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
            );
        }
        const data = await res.text();
        dispatch(storeInitApiState(JSON.parse(data)));
    } catch (error) {
        console.log(error);
    }

};
export const postDataClients = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const EDITOR = getState().client.editor;
        try {
            const res = await fetch(
                `${server}/clients`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            const jsonData = JSON.parse(data);
            dispatch(onSaveClient(jsonData.client, null));
            if (jsonData.add_building) {
                dispatch(onSaveObject(jsonData.object, null));
            }
        } catch (error) {
            console.log(error);
        }
    };
export const editDataClients = (client: IClient, clientId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const EDITOR = getState().client.editor;
        try {
            const res = await fetch(
                `${server}/clients/${clientId}`,

                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(EDITOR), //parameter
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveClient(JSON.parse(data), EDITOR.id));
        } catch (error) {
            console.log(error);
        }
    };

export const delDataClients = (clientId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/clients/${clientId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await res.text();
            dispatch(deleteClient(clientId));
        } catch (error) {
            console.log(error);
        }
    };


export const postDataContracts = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const CONTRACT_EDITOR = getState().contract.editor;
        console.log(CONTRACT_EDITOR)
        try {
            const res = await fetch(
                `${server}/contracts`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(CONTRACT_EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }

            const data = await res.text();

            dispatch(onSaveContract(JSON.parse(data), null));
        } catch (error) {
            console.log(error);
        }
    };
export const editDataContracts = (contract: IContract, contractId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const CONTRACT_EDITOR = getState().contract.editor;
        console.log(CONTRACT_EDITOR)
        try {
            const res = await fetch(
                `${server}/contracts/${contractId}`,

                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(CONTRACT_EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();

            console.log(data)
            dispatch(onSaveContract(JSON.parse(data), CONTRACT_EDITOR.id));
        } catch (error) {
            console.log(error);
        }
    };

export const delDataContracts = (contractId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/contracts/${contractId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await res.text();
            dispatch(deleteContract(contractId));
        } catch (error) {
            console.log(error);
        }
    };


export const postDataObjects = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const OBJECT_EDITOR = getState().object.editor;

        try {
            const res = await fetch(
                `${server}/objects`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(OBJECT_EDITOR),
                }
            );

            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveObject(JSON.parse(data), null));
        } catch (error) {
            console.log(error);
        }
    };
export const editDataObjects = (object: IObject, objectId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const OBJECT_EDITOR = getState().object.editor;
        try {
            const res = await fetch(
                `${server}/objects/${objectId}`,

                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(OBJECT_EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveObject(JSON.parse(data), OBJECT_EDITOR.id));
        } catch (error) {
            console.log(error);
        }
    };

export const delDataObjects = (objectId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/objects/${objectId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await res.text();
            dispatch(deleteObject(objectId));
        } catch (error) {
            console.log(error);
        }
    };

export const logout = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const LOGGED = getState().logged;
        try {
            const res = await fetch(
                `${server}/logout`,
                {
                    method: "POST",
                    headers: {
                        'Content-type': 'application/json',
                        'Accept': 'application/json',
                        'Authorization': `Bearer ${LOGGED.user.token}`
                    }
                }
            );
            const data = await res.text();
            dispatch({
                type: LOGOUT_USER,
            });

            window.document.location.href = "/";
        } catch (error) {

        }
    };


//Notes
export const postDataNotes = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const EDITOR = getState().client.options.note_editor;
        const CLIENT = getState().client.editor
        const NOTE = {

            user_id: EDITOR.user_id,
            client_id: CLIENT.id,
            note_content: EDITOR.note_content,
            note_headline: EDITOR.note_headline
        }
        try {
            const res = await fetch(
                `${server}/notes`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(NOTE),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveNote(JSON.parse(data), null));
        } catch (error) {
            console.log(error);
        }
    };
export const editDataNotes = (note: INote, noteId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const EDITOR = getState().client.options.note_editor;
        const NOTE = {
            id: EDITOR.id,
            user_id: EDITOR.user_id,
            client_id: EDITOR.client_id,
            note_content: EDITOR.note_content,
            note_headline: EDITOR.note_headline
        }
        try {
            const res = await fetch(
                `${server}/notes/${noteId}`,

                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(NOTE),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveNote(JSON.parse(data), noteId));
        } catch (error) {
            console.log(error);
        }
    };

export const delDataNotes = (noteId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/notes/${noteId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await res.text();
            dispatch(deleteNote(noteId));
        } catch (error) {
            console.log(error);
        }
    };


export const postDataUsers = () =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const USER_EDITOR = getState().user.editor;

        try {
            const res = await fetch(
                `${server}/users`,
                {
                    method: "POST",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(USER_EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }

            const data = await res.text();
            console.log(data)
            dispatch(onSaveUser(JSON.parse(data), null));
        } catch (error) {
            console.log(error);
        }
    };
export const editDataUsers = (user: ICreateUser, userId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        const USER_EDITOR = getState().user.editor;
        try {
            const res = await fetch(
                `${server}/users/${userId}`,

                {
                    method: "PATCH",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(USER_EDITOR),
                }
            );
            if (!res.ok) {
                throw Error(
                    "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
                );
            }
            const data = await res.text();
            dispatch(onSaveUser(JSON.parse(data), USER_EDITOR.id));
        } catch (error) {
            console.log(error);
        }
    };

export const delDataUsers = (userId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/users/${userId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Type": "application/json",
                    }
                }
            );
            const data = await res.text();
            dispatch(deleteUser(userId));
        } catch (error) {
            console.log(error);
        }
    };

//Files
export const downloadApiFiles = (fileId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const files = getState().api.files;
            const file = files.find((file) => file.id === fileId)
            const res = await fetch(
                `${server}/files/${fileId}`,
                {
                    method: "GET",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                        "Content-Description": "File Transfer",
                        "Content-Type": "application/pdf",
                    }
                }
            );

            const url = URL.createObjectURL(await res.blob())
            const a = document.createElement('a')
            a.setAttribute('href', url)
            a.setAttribute('download', `${file.name}`)
            a.click()
        } catch (error) {
            console.log(error);
        }
    };
// export const getApiFiles = () =>
//     async (dispatch: Dispatch, getState: () => GlobalState) => {
//         try {

//             const res = await fetch(
//                 `${server}/files`,
//                 {
//                     method: "GET",
//                     headers: {
//                         'Authorization': `Bearer ${sessionStorage.token}`,
//                         "Content-Description": "File Transfer",
//                         "Content-Type": "application/pdf",
//                     }
//                 }
//             );
//             const data = await res.text();
//            console.log(data)
           

//         } catch (error) {
//             console.log(error);
//         }
//     };
export const delApiFiles = (fileId: number) =>
    async (dispatch: Dispatch, getState: () => GlobalState) => {
        try {
            const res = await fetch(
                `${server}/files/${fileId}`,
                {
                    method: "DELETE",
                    headers: {
                        'Authorization': `Bearer ${sessionStorage.token}`,
                    }
                }
            );
            const data = await res.text();
            console.log(data);
            //dispatch(deleteFile(fileId));
        } catch (error) {
            console.log(error);
        }
    };
// export const postDataFile = (file: File) =>
//     async (dispatch: Dispatch, getState: () => GlobalState) => {
//         const EDITOR = getState().file.editor;
//         const CLIENT = getState().client.editor
//         const FILE = {

//             user_id: EDITOR.user_id,
//             client_id: CLIENT.id,
//             name: EDITOR.name,
//             file: file
//         }
//         try {
//             console.log('file sent')
//             const res = await fetch(
//                 `${server}/files`,
//                 {
//                     method: "POST",
//                     headers: {
//                         'Authorization': `Bearer ${sessionStorage.token}`,
//                         "Content-Type": "application/json",
//                     },
//                     body: JSON.stringify(FILE),
//                 }
//             );
//             if (!res.ok) {
//                 throw Error(
//                     "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
//                 );
//             }
//             const data = await res.text();
//             //dispatch(onSaveNote(JSON.parse(data), null));
//         } catch (error) {
//             console.log(error);
//         }
//     };



