import React from "react";
import { Box, Card, CardContent, Grid, Typography } from "@mui/material";
import { GlobalState } from "../../global";
import { useSelector } from "react-redux";
const Dashboard = () => {
    const clients = useSelector((state: GlobalState) => state.api.clients);
    const contracts = useSelector((state: GlobalState) => state.api.contracts);
    const objects = useSelector((state: GlobalState) => state.api.objects);
    return (
        <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
            <Grid container spacing={2}>
                <Grid item xl={12} sm={12} xs={12} paddingBottom={"1rem"}>
                    <Typography variant="h5" component="div">
                        Přehled
                    </Typography>
                </Grid>
            </Grid>
            <Grid container spacing={2}>
                <Grid item xl={2} sm={6} xs={12} paddingBottom={"1rem"}>
                    <Card>
                        <CardContent
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="h5" component="div">
                                {clients ? `${clients.length}` : "?"}
                              
                            </Typography>
                            <Typography variant="body2" component="div">
                                Počet klientů
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xl={2} sm={6} xs={12} paddingBottom={"1rem"}>
                    <Card>
                        <CardContent
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="h5" component="div">
                                {contracts ? `${contracts.length}`: "?"}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Počet zakázek
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid item xl={2} sm={6} xs={12} paddingBottom={"1rem"}>
                    <Card>
                        <CardContent
                            style={{
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                flexDirection: "column",
                                whiteSpace: "nowrap",
                            }}
                        >
                            <Typography variant="h5" component="div">
                                {objects ? `${objects.length}` : "?"}
                            </Typography>
                            <Typography variant="body2" component="div">
                                Počet objektů
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default Dashboard;
