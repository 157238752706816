import { Box, Grid, TextField, useMediaQuery } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { GlobalState } from "../../../../global";
import { theme } from "../../../../theme";
import { onEditNoteInEditor, resetNoteEditor, setNoteAsEdited } from "../../actions";
import { NOTE_EDITOR } from "../../constants";
import { INote } from "../../types";

const CreateUpdateNote = ({ note }: { note: INote }) => {
    return (
        <>
            <Box component={"form"}>
                <Grid container spacing={2}>
                    <Grid item xl={12} sm={12} xs={12}>
                        <NoteHeadline />
                        <NoteContent />
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

const NoteHeadline = () => {
    const NOTE_HEADLINE = useSelector(
        (state: GlobalState) => state.client.options.note_editor.note_headline
    );

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <TextField
            label="Nadpis"
            name="note_headline"
            value={NOTE_HEADLINE}
            sx={{ mb: 2 }}
            fullWidth
            variant="standard"
            // error={!VALID_NAME.status}
            onChange={(e) =>
                dispatch(onEditNoteInEditor(NOTE_EDITOR.NOTE_HEADLINE, `${e.target.value}`))
            }
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const NoteContent = () => {
    const NOTE_CONTENT = useSelector(
        (state: GlobalState) => state.client.options.note_editor.note_content
    );
    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const isMdUp = useMediaQuery(theme.breakpoints.up("md"));
    return (
        <TextField
            label="Poznámka"
            name="note_content"
            multiline
            rows={10}
            value={NOTE_CONTENT}
            sx={{ mb: 2 }}
            style={isMdUp ? { minWidth: "40em" } : {}}
            fullWidth
            variant="standard"
            // error={!VALID_NAME.status}
            onChange={(e) =>
                dispatch(onEditNoteInEditor(NOTE_EDITOR.NOTE_CONTENT, `${e.target.value}`))
            }
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

export default CreateUpdateNote;
