import { createSelector } from "@reduxjs/toolkit";
import { GlobalState } from "../../global";

export const getUserById = (id: number) =>
    createSelector(
        (state: GlobalState) => state.api.users,
        (list) => list.find((user) => Number(user.id) === id)
    );

export const getRoleById = (id: number) =>
    createSelector(
        (state: GlobalState) => state.static_db.roleTable,
        (list) => list.find((role) => Number(role.id) === id)
    );

export const getSelectedClientsIdsAndNames = createSelector(
    (state: GlobalState) => state.api.clients,
    (state: GlobalState) => state.user.editor.allow_clients_ids,
    (allclients, selectedclients) => allclients
        .filter((sel) => sel.id === selectedclients.find((id) => Number(id) === sel.id))
        .map((sel) => {
            return { id: sel.id, name: sel.name }
        })
)

export const getClientsIdsAndNamesWithoutSelected = createSelector(
    (state: GlobalState) => state.api.clients,
    (state: GlobalState) => state.user.editor.allow_clients_ids,
    (allclients, selectedclients) => allclients
        .filter((sel) => sel.id !== selectedclients.find((id) => id === sel.id))
        .map((sel) => {
            return { id: sel.id, name: sel.name }
        })
)

export const getAllUsersClientsById = (id: number) => createSelector(
    (state: GlobalState) => state.api.clients,
    (allClients) => allClients
        .filter((client) => client.user_id == id)
)