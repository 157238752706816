import {
    Box,
    Button,
    Card,
    CardContent,
    Checkbox,
    Divider,
    FormControlLabel,
    FormGroup,
    Grid,
    TextField,
    Typography,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { editDataClients, postDataClients, postDataObjects } from "../../../API";
import { GlobalState } from "../../../global";
import { changePage } from "../../actions";
import {
    onEditClient,
    resetEditor,
    setClientAsEdited,
    setClientOptionsAddBuilding,
    setObjectAsEditedCreate,
} from "../actions";
import { EDITOR } from "../constants";
import { getClientById } from "../selector";

const CreateUpdateClient = () => {
    const params = useParams();
    const navigate = useNavigate();
    const clients = useSelector((state: GlobalState) => state.api.clients);
    const index = clients?.findIndex((item) => item.id === Number(params.id));
    const client = useSelector(getClientById(index));
    const location = useLocation();

    const create = location.pathname === "/klienti/vytvorit";
    const USER = useSelector((state: GlobalState) => state.logged.user);

    const CLIENT = useSelector((state: GlobalState) => state.client.editor);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const ADD_BUILDING = useSelector((state: GlobalState) => state.client.editor.add_building);
    useEffect(() => {
        if (create) {
            dispatch(resetEditor(USER));
            dispatch(
                changePage({
                    name: "Vytvořit klienta",
                    path: location.pathname,
                    tree: [{ name: "Klienti", path: "/klienti/vytvorit" }],
                })
            );
        } else {
            dispatch(setClientAsEdited(Number(params.id)));
            dispatch(
                changePage({
                    name: `Upravit klienta ${params.id}`,
                    path: location.pathname,
                    tree: [
                        { name: "Klienti", path: "/klineti" },
                        { name: params.id, path: `/klineti/${params.id}` },
                    ],
                })
            );
        }
    }, []);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (create) {
            dispatch(postDataClients());
            console.log(CLIENT);
            dispatch(resetEditor(USER));
        } else {
            dispatch(editDataClients(client, Number(params.id)));
        }
        navigate(`/klienti/`);
    };

    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <Typography component="div" variant="h5" paddingBottom={"1rem"}>
                    {create ? `Přidat klienta` : `Upravit klienta ID${params.id}`}
                </Typography>
                <Box component={"form"} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} sm={12} xs={12} paddingBottom={"1rem"}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={6}>
                                            <ClientName />
                                            <ClientSurname />
                                            <ClientAddress />
                                            <ClientCity />
                                            <ClientZipcode />
                                        </Grid>
                                        <Grid item xs={12} lg={6}>
                                            <ClientEmail />
                                            <ClientPhone />
                                            {create && <ClientCheckbox />}
                                        </Grid>
                                        {ADD_BUILDING ? (
                                            <>
                                                <Grid item xs={12} lg={12}>
                                                    <Divider />
                                                </Grid>
                                                {!CLIENT?.id && 
                                                <Grid item xs={12} lg={6}>
                                                    <ClientCadastralTerritory />
                                                    <ClientNumberCadastralTerritory />
                                                    <ClientParcelNumber />
                                                </Grid>}
                                            </>
                                        ) : (
                                            ""
                                        )}
                                        <Grid item xs={12}>
                                            <Box
                                                display={"flex"}
                                                justifyContent="flex-end"
                                                gap={"0.5rem"}
                                            >
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    //disabled={denyButton}
                                                >
                                                    Uložit
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

const ClientEmail = () => {
    const EMAIL = useSelector((state: GlobalState) => state.client.editor.email);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="E-mail"
            name="email"
            autoComplete="email"
            value={EMAIL}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditClient(EDITOR.EMAIL, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const ClientName = () => {
    const NAME = useSelector((state: GlobalState) => state.client.editor.name);

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Jméno"
            name="name"
            autoComplete="given-name"
            value={NAME}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_ADDRESS.status}
            onChange={(e) => dispatch(onEditClient(EDITOR.NAME, `${e.target.value}`))}
            // helperText={!VALID_ADDRESS.status && VALID_ADDRESS.error}
        />
    );
};

const ClientAddress = () => {
    const ADDRESS = useSelector((state: GlobalState) => state.client.editor.address);

    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Adresa"
            name="address"
            autoComplete="street-address"
            value={ADDRESS}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditClient(EDITOR.ADDRESS, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const ClientCity = () => {
    const CITY = useSelector((state: GlobalState) => state.client.editor.city);

    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Město"
            name="city"
            autoComplete="address-level2"
            value={CITY}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditClient(EDITOR.CITY, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const ClientPhone = () => {
    const PHONE = useSelector((state: GlobalState) => state.client.editor.phone);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Telefon"
            name="phone"
            autoComplete="tel-local"
            value={PHONE}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditClient(EDITOR.PHONE, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const ClientSurname = () => {
    const SURNAME = useSelector((state: GlobalState) => state.client.editor.surname);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Příjmení"
            name="surname"
            autoComplete="family-name"
            value={SURNAME}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditClient(EDITOR.SURNAME, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const ClientZipcode = () => {
    const ZIPCODE = useSelector((state: GlobalState) => state.client.editor.zipcode);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="PSČ bez mezer - např.: 10400 "
            name="zipcode"
            autoComplete="postal-code"
            value={ZIPCODE}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditClient(EDITOR.ZIPCODE, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const ClientCheckbox = () => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const CHECKED = useSelector((state: GlobalState) => state.client.editor.add_building);
    useEffect(() => {}, [CHECKED]);
    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        dispatch(setClientOptionsAddBuilding(event.target.checked));
    };
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={CHECKED}
                        onChange={handleChange}
                        inputProps={{ "aria-label": "controlled" }}
                    />
                }
                label="Vytvořit zároveň budovu se stejnou adresou"
            />
        </FormGroup>
    );
};

// Cadastral copied to building {object}

const ClientCadastralTerritory = () => {
    const CADASTRAL_TERRITORY = useSelector(
        (state: GlobalState) => state.client.editor.cadastral_territory
    );

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Katastrální uzemí"
            name="cadastral_territory"
            value={CADASTRAL_TERRITORY}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) =>
                dispatch(onEditClient(EDITOR.CADASTRAL_TERRITORY, `${e.target.value}`))
            }
        />
    );
};

const ClientNumberCadastralTerritory = () => {
    const NUMBER_CADASTRAL_TERRITORY = useSelector(
        (state: GlobalState) => state.client.editor.number_cadastral_territory
    );

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Číslo katastrálního uzemí"
            name="number_cadastral_territory"
            value={NUMBER_CADASTRAL_TERRITORY}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) =>
                dispatch(onEditClient(EDITOR.NUMBER_CADASTRAL_TERRITORY, `${e.target.value}`))
            }
        />
    );
};

const ClientParcelNumber = () => {
    const PARCEL_NUMBER = useSelector((state: GlobalState) => state.client.editor.parcel_number);

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Parcelní číslo"
            name="parcel_number"
            value={PARCEL_NUMBER}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) => dispatch(onEditClient(EDITOR.PARCEL_NUMBER, `${e.target.value}`))}
        />
    );
};

export default CreateUpdateClient;
