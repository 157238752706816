import * as React from "react";
import Avatar from "@mui/material/Avatar";
import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Link from "@mui/material/Link";
import Box from "@mui/material/Box";
import LockOutlinedIcon from "@mui/icons-material/LockOutlined";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import { ThemeProvider } from "@mui/material/styles";
import { theme } from "../../../theme";
import { FormEvent } from "react";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { AnyAction } from "redux";
import { getInitStatic_dbState, getInitApiState } from "../../../API";
import { setUserAsLogged } from "../action";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {"Copyright © "}
            <Link color="inherit" href="https://lonsmin.cz/">
                Lonsmin.cz
            </Link>{" "}
            {new Date().getFullYear()}
        </Typography>
    );
}

export const Login = () => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const [MESSAGE, setMessage] = React.useState("");
    const [email, setEmail] = React.useState("");
    const [password, setPassword] = React.useState("");
    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault();
        try {
            // const res = await fetch("http://localhost:8000/api/login", {
                const res = await fetch("https://backend.renami.cz/api/login", {
                method: "POST",
                headers: {
                    "Content-type": "application/json",
                    Accept: "application/json",
                },
                body: JSON.stringify({
                    email: email,
                    password: password,
                }),
            });

            const data = await res.json();
            if (data.message == "WrongCreds") {
                setMessage("Špatné přihlašovací údaje");
            } else {
                sessionStorage.setItem("token", data.token);
                sessionStorage.setItem("id", data.user.id);
                dispatch(
                    setUserAsLogged({
                        user: {
                            id: data.user.id,
                            name: data.user.name,
                            email: data.user.email,
                            token: data.token,
                            allow_clients_ids: [],
                            role: Number(data.role),
                        },
                    })
                );
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs">
                <CssBaseline />
                <Box
                    sx={{
                        marginTop: 8,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: "primary.main" }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        DOTACEZELENA
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Heslo"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Typography
                            component="h1"
                            variant="body1"
                            color="error"
                            style={{ marginTop: "1.2em" }}
                        >
                            {`${MESSAGE}`}
                        </Typography>

                        <Button
                            onClick={handleSubmit}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            PŘIHLÁSIT
                        </Button>
                    </Box>
                </Box>
                <Copyright sx={{ mt: 8, mb: 4 }} />
            </Container>
        </ThemeProvider>
    );
};
