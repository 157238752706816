import * as React from "react";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import Checkbox from "@mui/material/Checkbox";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import {
    getAllUsersClientsById,
    getClientsIdsAndNamesWithoutSelected,
    getSelectedClientsIdsAndNames,
} from "../selector";
import { useState } from "react";
import { onChangeSelected } from "../action";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { AnyAction } from "redux";
import { Box } from "@mui/material";
import { GlobalState } from "../../../global";

function not(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a: readonly number[], b: readonly number[]) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a: readonly number[], b: readonly number[]) {
    return [...a, ...not(b, a)];
}

export default function TransferList({ setOpen, id }: { setOpen: Function; id: number }) {
    const AVELIBLE_CLIENTS = useSelector(getClientsIdsAndNamesWithoutSelected) || [];
    const ALL_CLIENTS = useSelector((state: GlobalState) => state.api.clients);
    const SELECTED_CLIENTS = useSelector(getSelectedClientsIdsAndNames) || [];
    const USERS_CLIENTS = useSelector(getAllUsersClientsById(id));
    const [checked, setChecked] = React.useState<readonly number[]>([]);
    const [left, setLeft] = React.useState<readonly number[]>([]);
    const [right, setRight] = useState<number[]>([]);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    React.useEffect(() => {
        const clients_users_ids = USERS_CLIENTS.map((c) => c.id);
        const avelible_users_ids = AVELIBLE_CLIENTS.map((c) => c.id);

        setLeft(avelible_users_ids.filter((n) => !clients_users_ids.includes(n)));
        setRight(SELECTED_CLIENTS.map((c) => c.id));
    }, [SELECTED_CLIENTS, AVELIBLE_CLIENTS]);

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value: number) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items: readonly number[]) => intersection(checked, items).length;

    const handleToggleAll = (items: readonly number[]) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        setRight(right.concat(leftChecked));
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        setLeft(left.concat(rightChecked));
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const customList = (items: readonly number[], side) => (
        <Card>
            <CardHeader
                sx={{ px: 2, py: 1 }}
                avatar={
                    <Checkbox
                        onClick={handleToggleAll(items)}
                        checked={numberOfChecked(items) === items.length && items.length !== 0}
                        indeterminate={
                            numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0
                        }
                        disabled={items.length === 0}
                        inputProps={{
                            "aria-label": "vybráno vše",
                        }}
                    />
                }
                subheader={`${numberOfChecked(items)}/${items.length} vybráno`}
            />
            <Divider />
            <List
                sx={{
                    width: 200,
                    height: 230,
                    bgcolor: "background.paper",
                    overflow: "auto",
                }}
                dense
                component="div"
                role="list"
            >
                {side === "left" &&
                    items.map((id) => {
                        const client = ALL_CLIENTS.filter((el) => el.name).find(
                            (turn) => turn.id === id
                        );
                        const labelId = `transfer-list-all-item-${client}-label`;

                        return (
                            client && (
                                <ListItem
                                    key={id}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(id)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                "aria-labelledby": labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={`${client.name} ${client.surname} (${client.id})`}
                                    />
                                </ListItem>
                            )
                        );
                    })}
                {side === "right" &&
                    items.map((id) => {
                        const client = ALL_CLIENTS.filter((el) => el.name).find(
                            (turn) => turn.id === id
                        );
                        const labelId = `transfer-list-all-item-${client}-label`;

                        return (
                            client && (
                                <ListItem
                                    key={id}
                                    role="listitem"
                                    button
                                    onClick={handleToggle(id)}
                                >
                                    <ListItemIcon>
                                        <Checkbox
                                            checked={checked.indexOf(id) !== -1}
                                            tabIndex={-1}
                                            disableRipple
                                            inputProps={{
                                                "aria-labelledby": labelId,
                                            }}
                                        />
                                    </ListItemIcon>
                                    <ListItemText
                                        id={labelId}
                                        primary={`${client.name} ${client.surname} (${client.id})`}
                                    />
                                </ListItem>
                            )
                        );
                    })}
                <ListItem />
            </List>
        </Card>
    );

    return (
        <>
            <Grid container spacing={2} justifyContent="center" alignItems="center">
                <Grid item>{customList(left, "left")}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                        >
                            &gt;
                        </Button>
                        <Button
                            sx={{ my: 0.5 }}
                            variant="outlined"
                            size="small"
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>{customList(right, "right")}</Grid>
            </Grid>
            <Box
                display={"flex"}
                justifyContent="flex-end"
                gap={"0.5rem"}
                style={{ paddingTop: "1em" }}
            >
                <Button
                    variant="contained"
                    color="primary"
                    onClick={() => {
                        dispatch(onChangeSelected(right));
                        setOpen(false);
                    }}
                >
                    ZAVŘÍT
                </Button>
            </Box>
        </>
    );
}
