import { Box, Button, ButtonGroup } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import { AnyAction, ThunkDispatch } from '@reduxjs/toolkit';
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { delApiFiles, downloadApiFiles, getInitApiState } from '../../../../API';
import { DATA_GRID_DISABLE_OUTLINED_CELL, locale } from '../../../../layout/constants';
import { FILE_DATATABLE_COLUMNS } from '../../constants';
import { getClientsFiles } from '../../selector';
import { IFileRow } from '../../types';
import FileDetail from './FilesDetail';

const FilesTable = ({ id }) => {
	const FILES = useSelector(getClientsFiles(Number(id)));
	const [rows, setRows] = useState<IFileRow[]>();
	const [pageSize, setPageSize] = useState(20);
	const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
	const handleDelete = (fileID: number) => {
		dispatch(delApiFiles(fileID));
		console.log(`delete file_id: ${fileID}`);
	}
	const handleDownload = (fileID: number) => {
		dispatch(downloadApiFiles(fileID));
		console.log(`download file_id: ${fileID}`);
	}
	const callInitApiState = () => {
		dispatch(getInitApiState());
	  };
	useEffect(() => {
		callInitApiState(); // Spustíme funkci okamžitě
		const intervalId = setInterval(callInitApiState, 3000);
	
		return () => {
		  clearInterval(intervalId); // Zastav interval, když komponenta unmountuje
		};
	  }, []);
	useEffect(() => {


		const fileRows: IFileRow[] = FILES.map((file, i) => {
			const row: IFileRow = {
				...file,
				button: (
					<ButtonGroup>
						<Button

							onClick={() => handleDownload(file.id)}
							variant="contained"
							color="primary"
						>
							Stáhnout
						</Button>

						<Button

							onClick={() => { 
								handleDelete(file.id);
								dispatch(getInitApiState());
							}
							}

							color="error"
						>
							Smazat
						</Button>
					</ButtonGroup>
				),
			};
			return row;
		});
		setRows(fileRows);
	}, [FILES]);
	return (
		<>
			{/* {FILES.map((file, i) => (
				<FileDetail file={file} key={`file_${i}`} />
			))} */}
			<Box style={{ width: "100%" }} >
				{rows && (
					<DataGrid
						sx={DATA_GRID_DISABLE_OUTLINED_CELL}
						disableSelectionOnClick
						hideFooterSelectedRowCount
						style={{ backgroundColor: "#FFF" }}
						columns={FILE_DATATABLE_COLUMNS}
						rows={rows}
						pageSize={pageSize}
						onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
						rowsPerPageOptions={[5, 10, 25, 50]}
						autoHeight
						localeText={locale}
					/>
				)}
			</Box>
		</>



	)
}

export default FilesTable