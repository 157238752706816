import { SafetyDivider } from "@mui/icons-material";
import {
    Box,
    Button,
    Card,
    CardContent,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
    Typography,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { GlobalState } from "../../../global";
import { theme } from "../../../theme";
import { NOTE_EDITOR } from "../../Clients/constants";
import { onEditUser, resetUserEditor, setUserAsEdited } from "../action";
import { AllowClientsModal } from "./AllowClientsModal";
import { delDataUsers, editDataUsers, postDataUsers } from "../../../API";
import { CREATE_USER_EDITOR } from "../constants";
const CreateUpdateUser = () => {
    const params = useParams();
    const userID = params.id;
    const navigate = useNavigate();
    const location = useLocation();
    const create = location.pathname === "/uzivatele/vytvorit";
    const [openAllowClientsModal, setOpenAllowClientsModal] = React.useState(false);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const user = useSelector((state: GlobalState) => state.user.editor);
    const logged_user = useSelector((state: GlobalState) => state.logged.user);
    useEffect(() => {
        if (create) {
            dispatch(resetUserEditor());
            // dispatch(
            //     changePage({
            //         name: "Vytvořit objekt",
            //         path: location.pathname,
            //         tree: [{ name: "Objekty", path: "/objekty/vytvorit" }],
            //     })
            // );
        } else {
            dispatch(setUserAsEdited(Number(params.id)));
            // dispatch(
            //     changePage({
            //         name: `Upravit objekt ${params.id}`,
            //         path: location.pathname,
            //         tree: [
            //             { name: "Objekty", path: "/klineti" },
            //             { name: params.id, path: `/objekty/${params.id}` },
            //         ],
            //     })
            // );
        }
    }, [create]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (create) {
            dispatch(postDataUsers());
            dispatch(resetUserEditor());
        } else {
            dispatch(editDataUsers(user, user.id));
        }
        navigate(`/uzivatele`);
    };
    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <AllowClientsModal
                    open={openAllowClientsModal}
                    setOpen={setOpenAllowClientsModal}
                    id={Number(userID)}
                />

                <Typography component="div" variant="h5" paddingBottom={"1rem"}>
                    {create ? `Přidat uživatele` : `Upravit uživatele ID${params.id}`}
                </Typography>
                <Card>
                    <CardContent>
                        <Box component={"form"} onSubmit={handleSubmit}>
                            <Grid container spacing={2}>
                                <Grid item xs={12} lg={6}>
                                    <UserEmail />
                                    <UserPassword create={create} />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <UserName />
                                    <UserRole />
                                </Grid>
                                <Grid item xs={12} lg={6}>
                                    <Button
                                        variant="contained"
                                        startIcon={<SafetyDivider />}
                                        onClick={() => {
                                            setOpenAllowClientsModal(!openAllowClientsModal);
                                        }}
                                        disabled={Number(userID) === logged_user.id}
                                    >
                                        EDITOVAT NEVLASTNÍ KLIENTY
                                    </Button>
                                </Grid>
                                <Grid item sm={12}>
                                    <Box display={"flex"} justifyContent="flex-end" gap={"0.5rem"}>
                                        <Button
                                            type="submit"
                                            variant="contained"
                                            //startIcon={<Save />}
                                            //disabled={denyButton}
                                        >
                                            ULOŽIT
                                        </Button>
                                    </Box>
                                </Grid>
                            </Grid>
                        </Box>
                    </CardContent>
                </Card>
            </Box>
        </>
    );
};

export default CreateUpdateUser;

const UserEmail = () => {
    const USER_EMAIL = useSelector((state: GlobalState) => state.user.editor.email);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    return (
        <TextField
            label="E-mail"
            name="email"
            inputProps={{
                autocomplete: "off",
            }}
            value={USER_EMAIL}
            sx={{ mb: 2 }}
            fullWidth
            variant="outlined"
            // error={!VALID_NAME.status}
            onChange={(e) => {
                dispatch(onEditUser(CREATE_USER_EDITOR.USER_EMAIL, `${e.target.value}`));
            }}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const UserPassword = ({ create }: { create: boolean }) => {
    const USER_PASSWORD = useSelector((state: GlobalState) => state.user.editor.password);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    return (
        <TextField
            fullWidth
            inputProps={{
                autocomplete: "new-password",
            }}
            name="password"
            label={create ? `Heslo` : `Heslo si mění uživatel sám`}
            type="password"
            value={USER_PASSWORD}
            sx={{ mb: 2 }}
            variant="outlined"
            disabled={!create}
            // error={!VALID_NAME.status}
            onChange={(e) => {
                dispatch(onEditUser(CREATE_USER_EDITOR.USER_PASSWORD, `${e.target.value}`));
            }}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const UserName = () => {
    const USER_NAME = useSelector((state: GlobalState) => state.user.editor.name);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    return (
        <TextField
            label="Jméno a Příjmení"
            name="name"
            value={USER_NAME}
            sx={{ mb: 2 }}
            fullWidth
            variant="outlined"
            // error={!VALID_NAME.status}
            onChange={(e) => {
                dispatch(onEditUser(CREATE_USER_EDITOR.USER_NAME, `${e.target.value}`));
            }}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const UserRole = () => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const ALL_USER_ROLES = useSelector((state: GlobalState) => state.static_db.roleTable);
    const SELECTED = useSelector((state: GlobalState) => state.user.editor.role);
    const onChangeRole = (event: SelectChangeEvent) => {
        dispatch(onEditUser(CREATE_USER_EDITOR.ROLE, event.target.value));
    };
    // // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">Role</InputLabel>
            <Select
                labelId={`select-label`}
                label="Role"
                name="role"
                value={`${SELECTED}`}
                sx={{ mb: 2 }}
                onChange={onChangeRole}
                //disabled={!create}
                // error={!VALID_NAME.status}
                // helperText={!VALID_NAME.status && VALID_NAME.error}s
            >
                {ALL_USER_ROLES.map((role) => {
                    return (
                        <MenuItem value={role.id} key={`db_service${role.id}`}>
                            {`${role.name}`}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
