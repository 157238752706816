import { Box, Grid } from "@mui/material";
import React from "react";
import { IOperation } from "../../../../database/types";
import { IContract } from "../../types";
import { OperationItemIcon } from "./OperationItemIcon";

export const OperationItem = ({
    operation,
    contract,
}: {
    operation: IOperation;
    contract: IContract;
}) => {
    return (
        <>
            <Box padding={"0.15em"}>
                <Grid container spacing={0}>
                    <Grid
                        item
                        xl={1}
                        sm={1}
                        xs={1}
                        style={{
                            display: "flex",
                            alignItems: "center",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        <OperationItemIcon operation={operation} />
                    </Grid>
                    <Grid
                        item
                        xl={8}
                        sm={8}
                        xs={8}
                        style={{
                            display: "flex",
                            justifyContent: "center",
                            flexDirection: "column",
                        }}
                    >
                        {operation.name}
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};
