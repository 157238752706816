import Drawer, { DrawerProps } from "@mui/material/Drawer";
import List from "@mui/material/List";
import { Group, AccountCircle, Dashboard } from "@mui/icons-material/";
import { IMenuItem } from "./menuTypes";
import MenuItemLink from "./MenuItemLink";
import { Box, Grid, ListItem, Typography } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import { GlobalState } from "../../../global";

const menuItems: IMenuItem[] = [
    { title: "Přehled", icon: <Dashboard />, route: "/" },
    { title: "Klienti", icon: <Group />, route: "/klienti" },
];
const AdminMenuItems: IMenuItem[] = [
    { title: "Přehled", icon: <Dashboard />, route: "/" },
    { title: "Klienti", icon: <Group />, route: "/klienti" },
    { title: "Uživatelé", icon: <AccountCircle />, route: "/uzivatele" },
];
export default function Navigator(props: DrawerProps) {
    const logged_user = useSelector((state: GlobalState) => state.logged.user);
    const { ...other } = props;
    const item = {
        py: "2px",
        px: 3,
        color: "rgba(255, 255, 255, 0.7)",
        cursor: "default",
    };

    const itemCategory = {
        boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
        py: 4,
        px: 3,
    };
    const Logo = () => {
        return (
            <>
                <Grid container spacing={0}>
                    <Grid item lg={12} md={12} sm={12}>
                        <div style={{ display: "block" }}>
                            <img src="img/logo.png" alt="DOTACEZELENA logo" />
                        </div>
                    </Grid>
                    <Grid item lg={12} md={12} sm={12}>
                        <Box justifyContent={"flex-end"} display="flex" paddingBottom={"1em"}>
                            <Typography variant="body1">v1.0.2</Typography>
                        </Box>
                    </Grid>
                </Grid>
            </>
        );
    };
    return (
        <Drawer variant="permanent" {...other}>
            <List disablePadding>
                <ListItem sx={{ ...item, ...itemCategory, fontSize: 22, color: "#fff" }}>
                    <Logo />
                </ListItem>
                {logged_user.role === 0 &&
                    AdminMenuItems.map((link, id) => (
                        <MenuItemLink
                            key={id}
                            route={link.route}
                            title={link.title}
                            icon={link.icon}
                        />
                    ))}
                {logged_user.role !== 0 &&
                    menuItems.map((link, id) => (
                        <MenuItemLink
                            key={id}
                            route={link.route}
                            title={link.title}
                            icon={link.icon}
                        />
                    ))}
            </List>
        </Drawer>
    );
}
