import { Routes, Route } from "react-router-dom";
import Clients from "../pages/Clients/components/Clients";
import Dashboard from "../pages/Dashboard/Dashboard";
import React from "react";
import CreateUpdateClient from "../pages/Clients/components/CreateUpdateClient";
import ClientDetail from "../pages/Clients/components/ClientDetail";
import CreateUpdateContract from "../pages/Clients/components/contracts/CreateUpdateContract";
import CreateUpdateObject from "../pages/Clients/components/objects/CreateUpdateObject";
import CreateUpdateOwner from "../pages/Clients/components/objects/CreateUpdateOwner";
import Users from "../pages/Users/components/Users";
import { UserDetail } from "../pages/Users/components/UserDetail";
import CreateUpdateUser from "../pages/Users/components/CreateUpdateUser";

const Router = () => {
    return (
        <>
            <Routes>
                <Route path="/" element={<Dashboard />} />
                <Route path="/klienti" element={<Clients />} />
                <Route path="/klienti/vytvorit" element={<CreateUpdateClient />} />
                <Route path="/klienti/:id/editovat" element={<CreateUpdateClient />} />
                <Route path="/klienti/:id" element={<ClientDetail />} />
                <Route path="/zakazky/vytvorit" element={<CreateUpdateContract />} />
                <Route path="/zakazky/:id/editovat" element={<CreateUpdateContract />} />
                <Route path="/objekty/vytvorit" element={<CreateUpdateObject />} />
                <Route path="/objekty/:id/editovat" element={<CreateUpdateObject />} />
                <Route path="/objekty/:id/vlastnik/vytvorit" element={<CreateUpdateOwner />} />
                <Route path="/uzivatele" element={<Users />} />
                <Route path="/uzivatele/:id" element={<UserDetail />} />
                <Route path="/uzivatele/vytvorit" element={<CreateUpdateUser />} />
                <Route path="/uzivatele/:id/editovat" element={<CreateUpdateUser />} />
            </Routes>
        </>
    );
};

export default Router;
