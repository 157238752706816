import { Dispatch } from "redux";
import { IObject, IOperation } from "../../database/types";
import { GlobalState } from "../../global";
import { IUser } from "../Users/types";
import * as constants from "./constants";
import { IContract } from "./types";

export const setNoteAsEdited = (id: number) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        const note = getState().api.notes.find(note => note.id === id);
        dispatch({
            note,
            type: constants.SET_NOTE_AS_EDITED,
        });
    }
export const resetNoteEditor = (user: IUser, clientId: number) => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        user,
        clientId,
        type: constants.RESET_NOTE_EDITOR,
    });
};
export const onEditNoteInEditor = (noteKey: constants.NOTE_EDITOR, value: string) => ({
    noteKey,
    value,
    type: constants.EDIT_NOTE,
});

export const setClientOptionsAddBuilding = (checked: boolean) => ({
    checked,
    type: constants.OPTIONS_SET_ADD_BUILDING,
});

export const setClientAsEdited = (id: number) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        const client = getState().api.clients.find(client => client.id === id);
        dispatch({
            client,
            type: constants.SET_CLIENT_AS_EDITED,
        });
    }
export const onEditClient = (key: constants.EDITOR, value: string) => ({
    key,
    value,
    type: constants.EDIT_CLIENT,
});
export const resetEditor = (user: IUser) => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        user,
        type: constants.RESET_EDITOR,
    });
};

export const setContractAsEdited = (id: number) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        const contract = getState().api.contracts.find(contract => contract.id === id);

        dispatch({
            contract,
            type: constants.SET_CONTRACT_AS_EDITED,
        });
    }
export const setContractAsEditedCreate = (contract: IContract) => (
    {
        contract,
        type: constants.SET_CONTRACT_AS_EDITED_CREATE,
    });
export const setContractOperationsAsEdited = (operation: IOperation[]) => (
    {
        operation,
        type: constants.SET_CONTRACT_OPERATION_AS_EDITED,

    })
export const setContractOperationStatusAsEdited = (status: number, operationId: number) =>
(
    {
        status,
        operationId,
        type: constants.SET_CONTRACT_OPERATION_STATUS_AS_EDITED,

    })
export const onEditContract = (conKey: constants.CONTRACT_EDITOR, value: string | boolean | number) => ({
    conKey,
    value,
    type: constants.EDIT_CONTRACT,
});
export const resetContractEditor = () => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        type: constants.RESET_CONTRACT_EDITOR,
    });
};


export const setObjectAsEdited = (id: number) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        const object = getState().api.objects.find(object => object.id === id);
        dispatch({
            object,
            type: constants.SET_OBJECT_AS_EDITED,
        });
    }
export const onEditObject = (objKey: constants.OBJECT_EDITOR, value: string | boolean | number) => ({
    objKey,
    value,
    type: constants.EDIT_OBJECT,
});
export const resetObjectEditor = () => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        type: constants.RESET_OBJECT_EDITOR,
    });
};

export const setObjectAsEditedCreate = (object: IObject) => (
    {
        object,
        type: constants.SET_OBJECT_AS_EDITED_CREATE,
    });
export const setClientToOwnerEditor = (ownerId: number) => (
    {
        ownerId,
        type: constants.SET_CLIENT_TO_OWNER_EDITOR
    }
)
export const resetObjectOwnerEditor = () => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        type: constants.RESET_OBJECT_OWNER_EDITOR,
    });
}

export const setOwnerToObjectEditor = (ownerId: number) => (
    {
        ownerId,
        type: constants.SET_OWNER_TO_OBJECT_EDITOR
    }
)

export const onEditOwnerClient = (ownKey: constants.OWNER_EDITOR, value: string | boolean | number) => ({
    ownKey,
    value,
    type: constants.EDIT_OWNER_CLIENT,
});

export const removeClientFromOwnerEditor = (ownerId: number) => ({
    ownerId,
    type: constants.REMOVE_OWNER_CLIENT,
});