export const INIT_DATA_STORE = 'init_data_store';
export const INIT_API_DATA_STORE = 'init_api_data_store';

export const USERS_DATA_STORE = 'users_data_store';
export const CLIENTS_DATA_STORE = 'clients_data_store';
export const CONTRACTS_DATA_STORE = 'contracts_data_store';
export const OBJECTS_DATA_STORE = 'objects_data_store';

export const DELETE_CLIENT = 'clients_del_client';

export const ADD_CLIENT = 'clients_add_client';
export const SAVE_CLIENT = 'clients_save_client';
export const EDIT_CLIENT = 'clients_edit_client';

export const DELETE_CONTRACT = 'clients_del_contract';
export const SAVE_CONTRACT = 'clients_save_contract';

export const DELETE_OBJECT = 'clients_del_object';
export const SAVE_OBJECT = 'clients_save_object';

export const DELETE_NOTE = `notes_del_note`;
export const SAVE_NOTE = `notes_save_note`;

export const DELETE_USER = `users_del_user`;
export const SAVE_USER = `users_save_user`;

export const SAVE_FILE = `files_save_files`;