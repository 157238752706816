import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  Grid,
  useMediaQuery,
  Select,
  MenuItem,
  SelectChangeEvent,
  InputLabel,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { getInitApiState, server } from "../../../../API";
import { GlobalState } from "../../../../global";

export interface INoteModalProps {
  open: boolean;
  setOpen: Function;
}
export const FileModal = ({ open, setOpen }: INoteModalProps) => {
  const [UPLOADED_FILE, setUploadedFile] = useState<FileList>();
  console.log("file_come", UPLOADED_FILE);
  const [FILES, setFiles] = useState([]);

  const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
  //const EDITOR = useSelector((state: GlobalState) => state.file.editor);
  const CLIENT = useSelector((state: GlobalState) => state.client.editor);
  const USER = useSelector((state: GlobalState) => state.logged.user);
  const handleSubmit = async (e: FormEvent) => {
    // const FILE = {
    //     user_id: USER.id,
    //     client_id: CLIENT.id,
    //     name: EDITOR.name,
    //     file: UPLOADED_FILE,
    // };

    try {
      const formData = new FormData();
      for (let i = 0; i < UPLOADED_FILE.length; i++) {
        formData.append("files[]", UPLOADED_FILE[i]);
      }
      formData.append("user_id", `${USER.id}`);
      formData.append("client_id", `${CLIENT.id}`);
      // formData.append("directory", `${CLIENT.directory}`);
      formData.append("directory", ` `);
      const res = await fetch(`${server}/files`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${sessionStorage.token}`,
        },
        body: formData,
      });
      const data = await res.text();
      // console.log('response',data)
      dispatch(getInitApiState());
      if (!res.ok) {
        throw Error(
          "Zkontrolujte prosím Vaše připojení k internetu a zkuste to znovu"
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(!open)}
      aria-labelledby="scroll-dialog-title"
      aria-describedby="scroll-dialog-description"
      scroll={"paper"}
      maxWidth={"xl"}
    >
      <DialogTitle id="scroll-dialog-title">Nahrát soubor</DialogTitle>
      <DialogContent dividers sx={{ width: 500 }}>
        <CreateUpdateFile
          setUploadedFile={setUploadedFile}
          FILES={FILES}
          setFiles={setFiles}
        />
      </DialogContent>
      <DialogActions>
        <Button
          variant="outlined"
          color="primary"
          sx={{ ml: "0.5rem" }}
          onClick={() => {
            setOpen(!open);
          }}
        >
          Zrušit
        </Button>
        <Button
          variant="contained"
          color="primary"
          sx={{ ml: "0.5rem" }}
          onClick={(e) => {
            handleSubmit(e);
            setOpen(!open);
          }}
        >
          UPLOAD
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const CreateUpdateFile = ({
  setUploadedFile,
  FILES,
  setFiles,
}: {
  setUploadedFile: Function;
  FILES: any;
  setFiles: Function;
}) => {
  const onChange = (e) => {
    console.log(e.target.files)
    setFiles(e.target.files);
    setUploadedFile(e.target.files);
  };
  enum DIRECTORY {
    TECHNOLOGY = "technology",
    WINDOWS =  "windows" 
  }
  const [DIR,setDir] = useState(DIRECTORY.TECHNOLOGY);
  const handleChange = (event: SelectChangeEvent) => {

  };
  const filesList = FILES ? [...FILES] : [];

  
  
  return (
    <>
      <Box component={"form"}>
        <Grid container spacing={2}>
          <Grid item xl={12} sm={12} xs={12}>
            <form encType="multipart/form-data">
              <Grid container spacing={2}>
                <Grid item xl={12} sm={12} xs={12}>
                  {/* <InputLabel id="demo-simple-select-label">Složka</InputLabel>
                   <Select
                    fullWidth
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={DIR}
                    onChange={handleChange}
                  >
                    {
                      Object.keys(DIRECTORY)
                      .map((key,index) => 
                      (<MenuItem key={`key_${index}_`} value={DIRECTORY[key]}>{key}</MenuItem>))
                    }
                  </Select> */}
                </Grid>
                <Grid item xl={12} sm={12} xs={12}>
                  <input
                    accept="*"
                    name="files[]"
                    style={{ display: "none" }}
                    id="raised-button-file"
                    onChange={onChange}
                    type="file"
                    multiple
                  />
                  <label htmlFor="raised-button-file">
                    <Button variant="contained" component="span">
                      Vybrat
                    </Button>
                    <label htmlFor="customFile" style={{ marginLeft: "1em" }}>
                    <ul>
                    {filesList.map((file, i) => (
                      <li key={i}>
                        {file.name}
                      </li>
                    ))}
                    </ul>
                    </label>
                  </label>
                </Grid>
              </Grid>
            </form>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};
