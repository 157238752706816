import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    Typography,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { delDataNotes, editDataNotes, postDataNotes } from "../../../../API";
import { GlobalState } from "../../../../global";
import { resetNoteEditor } from "../../actions";
import { INote } from "../../types";
import CreateUpdateNote from "./CreateUpdateNote";
export interface INoteModalProps {
    open: boolean;
    setOpen: Function;
}
export const NoteModal = ({ open, setOpen }: INoteModalProps) => {
    const NOTE = useSelector((state: GlobalState) => state.client.options.note_editor);
    const USER = useSelector((state: GlobalState) => state.logged.user);
    const CLIENT = useSelector((state: GlobalState) => state.client.editor);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (NOTE.id === null) {
            dispatch(postDataNotes());
            dispatch(resetNoteEditor(USER, CLIENT.id));
        } else {
            dispatch(editDataNotes(NOTE, NOTE.id));
        }
    };
    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            scroll={"paper"}
            maxWidth={"xl"}
        >
            <DialogTitle id="scroll-dialog-title">Poznámkový blok</DialogTitle>
            <DialogContent dividers>
                <CreateUpdateNote note={NOTE} />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    Zrušit
                </Button>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        dispatch(delDataNotes(NOTE.id));
                        setOpen(!open);
                    }}
                    style={{ marginRight: "1em" }}
                >
                    Smazat
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: "0.5rem" }}
                    onClick={(e) => {
                        handleSubmit(e);
                        setOpen(!open);
                    }}
                >
                    ULOŽIT
                </Button>
            </DialogActions>
        </Dialog>
    );
};
