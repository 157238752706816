import { Add } from "@mui/icons-material";
import { Box, Button, ButtonGroup, Card, CardContent, Grid } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { delDataUsers } from "../../../API";
import { GlobalState } from "../../../global";
import { DATA_GRID_DISABLE_OUTLINED_CELL, locale } from "../../../layout/constants";
import { changePage } from "../../actions";
import { USERS_COLUMNS } from "../constants";
import { getRoleById } from "../selector";
import { IUserRow } from "../types";

const Users = () => {
    const [pageSize, setPageSize] = useState(25);
    const [rows, setRows] = useState<IUserRow[]>();

    const users = useSelector((state: GlobalState) => state.api.users) || [];

    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const roles = useSelector((state: GlobalState) => state.static_db.roleTable);
    useEffect(() => {
        dispatch(
            changePage({
                name: "Uživatelé",
                path: "/uzivatele",
                tree: [{ name: "Uživatelé" }],
            })
        );
        const userRows: IUserRow[] = users.map((user, i) => {
            const row: IUserRow = {
                ...user,
                name_role: `${roles[user.role].name}`,
                button: (
                    <ButtonGroup>
                        <Button to={`/uzivatele/${user.id}`} component={Link} variant="contained">
                            Detail
                        </Button>

                        <Button
                            color="error"
                            variant="outlined"
                            onClick={() => dispatch(delDataUsers(Number(user.id)))}
                            // startIcon={<Save />}
                            //disabled={denyButton}
                        >
                            SMAZAT
                        </Button>
                    </ButtonGroup>
                ),
            };
            return row;
        });
        setRows(userRows);
    }, [users]);
    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <Box justifyContent={"flex-end"} display="flex" paddingBottom={"1rem"}>
                    <Button
                        to={`/uzivatele/vytvorit`}
                        component={Link}
                        startIcon={<Add />}
                        variant="contained"
                    >
                        Přidat uživatele
                    </Button>
                </Box>
                <Box style={{ width: "100%" }} paddingBottom={"1rem"}>
                    {rows && (
                        <DataGrid
                            sx={DATA_GRID_DISABLE_OUTLINED_CELL}
                            disableSelectionOnClick
                            hideFooterSelectedRowCount
                            style={{ backgroundColor: "#FFF" }}
                            columns={USERS_COLUMNS}
                            rows={rows}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            autoHeight
                            localeText={locale}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Users;
