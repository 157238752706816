import { GridColDef } from "@mui/x-data-grid";

/*
|----------------------------------------------------------------|
| SYSTEM USERS ROLE                                              |
|----------------------------------------------------------------|
| 0 SuperAdmin                                                   |
| 1 Admin                                                        |
| 2 Contractor                                                   |
| 3 Operator                                                     |
| 4 Contractor & Operator                                        |
| 5 Client users                                                 |
*/
const PLUGIN = "USER";

export const SET_USER_AS_LOGGED = `${PLUGIN}_set_user_as_logged`;
export const LOGOUT_USER = `${PLUGIN}_reset_user`

export const USERS_COLUMNS: GridColDef[] = [
    { field: "id", headerName: "ID", width: 65, hideable: false },
    { field: "email", headerName: "E-mail", width: 200 },
    { field: "name", headerName: "Jméno", width: 130 },
    { field: "name_role", headerName: "Role", width: 180 },
    {
        field: "button",
        headerName: "Akce",
        width: 280,
        sortable: false,
        filterable: false,
        renderCell(params) {
            return params.value;
        },
    },
    { field: "allow_clients_ids", headerName: "Povolené klientské ID", width: 200 },


];

export enum CREATE_USER_EDITOR {
    USER_NAME = "name",
    USER_EMAIL = "email",
    USER_PASSWORD = "password",
    ROLE = "role"
}

export const SET_USER_AS_EDITED = `${PLUGIN}_set_user_as_edited`;
export const EDIT_USER = `${PLUGIN}_edit_user`;
export const RESET_USER_EDITOR = `${PLUGIN}_reset_user_editor`;
export const CHANGE_SELECTED = `${PLUGIN}_change_selected_allow_clients`