import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { editDataClients, postDataClients } from "../../../../API";
import { GlobalState } from "../../../../global";
import { changePage } from "../../../actions";
import {
    onEditClient,
    onEditOwnerClient,
    resetEditor,
    resetObjectOwnerEditor,
    setClientAsEdited,
    setClientToOwnerEditor,
} from "../../actions";
import { EDITOR, OWNER_EDITOR } from "../../constants";
import { getClientById } from "../../selector";

const CreateUpdateOwner = () => {
    const params = useParams();
    const navigate = useNavigate();
    // const clients = useSelector((state: GlobalState) => state.api.clients);
    // const index = clients.findIndex((item) => item.id === Number(params.id));
    // const client = useSelector(getClientById(index));
    // const location = useLocation();

    const create = window.location.pathname === `/objekty/${params.id}/vlastnik/vytvorit`;

    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <>
            <Box component={"form"}>
                <Grid container spacing={2}>
                    <Grid item xl={12} sm={12} xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={12} lg={6}>
                                {/* <TurnoutID index={index} /> */}
                                <OwnerName />
                                <OwnerSurname />

                                <OwnerAddress />
                                <OwnerCity />
                            </Grid>
                            <Grid item xs={12} lg={6}>
                                <OwnerEmail />
                                <OwnerPhone />

                                <OwnerZipcode />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </>
    );
};

const OwnerEmail = () => {
    const EMAIL = useSelector((state: GlobalState) => state.object.owner_editor.email);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="E-mail"
            name="email"
            autoComplete="email"
            value={EMAIL}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.EMAIL, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const OwnerName = () => {
    const NAME = useSelector((state: GlobalState) => state.object.owner_editor.name);

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Jméno"
            name="name"
            autoComplete="given-name"
            value={NAME}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_ADDRESS.status}
            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.NAME, `${e.target.value}`))}
            // helperText={!VALID_ADDRESS.status && VALID_ADDRESS.error}
        />
    );
};

const OwnerAddress = () => {
    const ADDRESS = useSelector((state: GlobalState) => state.object.owner_editor.address);

    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Adresa"
            name="address"
            autoComplete="street-address"
            value={ADDRESS}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.ADDRESS, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const OwnerCity = () => {
    const CITY = useSelector((state: GlobalState) => state.object.owner_editor.city);

    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Město"
            name="city"
            autoComplete="address-level2"
            value={CITY}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.CITY, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const OwnerPhone = () => {
    const PHONE = useSelector((state: GlobalState) => state.object.owner_editor.phone);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Telefon"
            name="phone"
            autoComplete="tel-local"
            value={PHONE}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.PHONE, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const OwnerSurname = () => {
    const SURNAME = useSelector((state: GlobalState) => state.object.owner_editor.surname);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Příjemní"
            name="surname"
            autoComplete="family-name"
            value={SURNAME}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.SURNAME, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

const OwnerZipcode = () => {
    const ZIPCODE = useSelector((state: GlobalState) => state.object.owner_editor.zipcode);

    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="PSČ bez mezer - např.: 10400 "
            name="zipcode"
            autoComplete="postal-code"
            value={ZIPCODE}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditOwnerClient(OWNER_EDITOR.ZIPCODE, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

export default CreateUpdateOwner;
