import {
    Box,
    Grid,
    Card,
    CardContent,
    Typography,
    Button,
    TextField,
    Select,
    InputLabel,
    MenuItem,
    FormControl,
    SelectChangeEvent,
    Checkbox,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { AnyAction } from "redux";
import { editDataContracts, postDataContracts } from "../../../../API";
import { IOperation } from "../../../../database/types";
import { GlobalState } from "../../../../global";

import {
    onEditContract,
    resetContractEditor,
    setContractAsEdited,
    setContractAsEditedCreate,
    setContractOperationsAsEdited,
    setContractOperationStatusAsEdited,
} from "../../actions";
import { CONTRACT_EDITOR } from "../../constants";
import { getClientsBuildings } from "../../selector";
import { IClient } from "../../types";

const CreateUpdateContract = () => {
    const location = useLocation();
    const params = useParams();
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const navigate = useNavigate();

    const create = location.pathname === "/zakazky/vytvorit";

    const contracts = useSelector((state: GlobalState) => state.api.contracts);
    const [SELECT_INPUT, setSelectInput] = useState(0);
    const [SELECT_BUILDING, setSelectBuilding] = useState(0);
    const oneContract = contracts.find((item) => item.id === Number(params.id));
    const CLIENT = useSelector((state: GlobalState) => state.client.editor);
    const USER = useSelector((state: GlobalState) => state.logged.user);
    const BUILDINGS = useSelector(getClientsBuildings(Number(CLIENT.id)));

    const DB_SERVICES = useSelector((state: GlobalState) => state.static_db.services).find(
        (item) => item.id === SELECT_INPUT
    );

    const contract = useSelector((state: GlobalState) => state.contract.editor);

    useEffect(() => {
        if (create) {
            dispatch(resetContractEditor());
            dispatch(
                setContractAsEditedCreate({
                    id: null,
                    user_id: USER.id,
                    building: BUILDINGS[SELECT_BUILDING].id,
                    client_id: CLIENT.id,
                    service: SELECT_INPUT,
                    operation: [],
                    agreedPrice: 0,
                    deposit: false,
                })
            );
            dispatch(setContractOperationsAsEdited(DB_SERVICES.operation));
        } else {
            setSelectInput(oneContract.service);
            dispatch(setContractAsEdited(oneContract.id));
            dispatch(setContractOperationsAsEdited(oneContract.operation));

            // dispatch(
            //     changePage({
            //         name: `Upravit zakázku ${params.id}`,
            //         path: location.pathname,
            //         tree: [
            //             { name: "Zakázky", path: "/zakazky" },
            //             { name: `${contract.id}`, path: `/zakazky/${contract.id}` },
            //         ],
            //     })
            // );
        }
    }, [oneContract]);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (create) {
            dispatch(postDataContracts());
            dispatch(resetContractEditor());
        } else {
            dispatch(editDataContracts(contract, contract.id));
        }

        navigate(`/klienti/${CLIENT.id}`);
    };

    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <Typography component="div" variant="h5" paddingBottom={"1rem"}>
                    {create ? `Přidat zakázku` : `Upravit zakázku ID${params.id}`}
                </Typography>
                <Box component={"form"} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item xl={12} sm={12} xs={12} paddingBottom={"1rem"}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            <ContractService
                                                setSelectInput={setSelectInput}
                                                create={create}
                                            />
                                            <ContractBuilding
                                                client={CLIENT}
                                                selectedBuilding={SELECT_BUILDING}
                                                setSelectBuilding={setSelectBuilding}
                                                create={create}
                                            />
                                            <Grid container>
                                                <Grid item xs={12} lg={6}>
                                                    <ContractAgreedPrice />
                                                </Grid>
                                                <Grid item xs={12} lg={6}>
                                                    <ContractDeposit />
                                                </Grid>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                    <Grid container spacing={2}>
                        <Grid item xl={12} sm={12} xs={12} paddingBottom={"1rem"}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item xs={12} lg={12}>
                                            {DB_SERVICES &&
                                                create &&
                                                DB_SERVICES.operation.map((oper, i) => {
                                                    if (oper.supplier === "client") {
                                                        return (
                                                            <ContractOperation
                                                                key={`operation_${i}`}
                                                                operation={oper}
                                                                create={create}
                                                            />
                                                        );
                                                    }
                                                    if (oper.supplier === "contractor") {
                                                        return (
                                                            <ContractOperation
                                                                key={`operation_${i}`}
                                                                operation={oper}
                                                                create={create}
                                                            />
                                                        );
                                                    }
                                                })}

                                            {oneContract &&
                                                !create &&
                                                oneContract.operation.map((oper, i) => {
                                                    if (oper.supplier === "client") {
                                                        return (
                                                            <ContractOperation
                                                                key={`operation_${i}`}
                                                                operation={oper}
                                                                create={create}
                                                            />
                                                        );
                                                    }
                                                    if (oper.supplier === "contractor") {
                                                        return (
                                                            <ContractOperation
                                                                key={`operation_${i}`}
                                                                operation={oper}
                                                                create={create}
                                                            />
                                                        );
                                                    }
                                                })}
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Box
                                                display={"flex"}
                                                justifyContent="flex-end"
                                                gap={"0.5rem"}
                                            >
                                                {/* {!create && (
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    onClick={handleDelete}
                                                >
                                                    Smazat
                                                </Button>
                                            )} */}
                                                <Button
                                                    variant="contained"
                                                    type="submit"
                                                    //disabled={denyButton}
                                                >
                                                    Uložit
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

export default CreateUpdateContract;

const ContractService = ({
    setSelectInput,
    create,
}: {
    setSelectInput: (number: number) => void;
    create: boolean;
}) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const DB_ALL_SERVICES = useSelector((state: GlobalState) => state.static_db.services);
    const SELECTED = useSelector((state: GlobalState) => state.contract.editor.service);

    const onChangeService = (event: SelectChangeEvent) => {
        dispatch(
            setContractOperationsAsEdited(DB_ALL_SERVICES[Number(event.target.value)].operation)
        );
        dispatch(onEditContract(CONTRACT_EDITOR.SERVICE, event.target.value));
        setSelectInput(Number(event.target.value));
    };
    // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">Služba</InputLabel>
            <Select
                labelId={`select-label`}
                label="Služba"
                name="service"
                value={`${SELECTED}`}
                sx={{ mb: 2 }}
                onChange={onChangeService}
                disabled={!create}
                // error={!VALID_NAME.status}
                // onChange={(e) => dispatch(onEditContract(EDITOR.EMAIL, `${e.target.value}`))}
                // helperText={!VALID_NAME.status && VALID_NAME.error}
            >
                {DB_ALL_SERVICES.map((dbService) => {
                    return (
                        <MenuItem value={dbService.id} key={`db_service${dbService.id}`}>
                            {dbService.name}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

const ContractBuilding = ({
    client,
    selectedBuilding,
    setSelectBuilding,
    create,
}: {
    client: IClient;
    selectedBuilding: number;
    setSelectBuilding: (number: number) => void;
    create: boolean;
}) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const ALL_CLIENT_BUILDINGS = useSelector(getClientsBuildings(client.id));
    const SELECTED = useSelector((state: GlobalState) => state.contract.editor.building);
    const onLoad = () => {};
    const onChangeBuilding = (event: SelectChangeEvent) => {
        dispatch(onEditContract(CONTRACT_EDITOR.BUILDING, event.target.value));
        setSelectBuilding(Number(event.target.value));
    };
    // // const VALID_NAME = useSelector(getValidTurnout(EDITOR.NAME));
    return (
        <FormControl fullWidth>
            <InputLabel id="select-label">Budova</InputLabel>
            <Select
                labelId={`select-label`}
                label="Budova"
                name="building"
                value={create ? `${ALL_CLIENT_BUILDINGS[0].id}` : `${SELECTED}`}
                sx={{ mb: 2 }}
                onChange={onChangeBuilding}
                //disabled={!create}
                // error={!VALID_NAME.status}
                // helperText={!VALID_NAME.status && VALID_NAME.error}s
            >
                {ALL_CLIENT_BUILDINGS.map((building) => {
                    return (
                        <MenuItem value={building.id} key={`db_service${building.id}`}>
                            {`${building.city} | ${building.address}`}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};

const ContractAgreedPrice = () => {
    const AGREED_PRICE = useSelector((state: GlobalState) => state.contract.editor.agreedPrice);
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const USER = useSelector((state: GlobalState) => state.logged.user);

    return (
        <TextField
            label="Domluvená cena - bez mezer"
            name="agreedPrice"
            value={AGREED_PRICE}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            onChange={(e) =>
                dispatch(onEditContract(CONTRACT_EDITOR.AGREED_PRICE, `${e.target.value}`))
            }
            disabled={!(USER.role == 0)}
            // error={!VALID_ADDRESS.status}
            //onChange={(e) => dispatch(onEditContract(EDITOR.NAME, `${e.target.value}`))}
            // helperText={!VALID_ADDRESS.status && VALID_ADDRESS.error}
        />
    );
};

const ContractDeposit = () => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const DEPOSIT = useSelector((state: GlobalState) => state.contract.editor.deposit);
    const onChangeDeposit = (event: React.ChangeEvent<HTMLInputElement>) => {
        setChecked(!checked);
        dispatch(onEditContract(CONTRACT_EDITOR.DEPOSIT, !checked));
    };
    useEffect(() => {
        setChecked(DEPOSIT);
    }, [DEPOSIT]);
    const [checked, setChecked] = React.useState(false);
    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    return (
        <>
            <Grid
                container
                sx={{
                    display: "flex",
                    alignItems: "center",
                    p: 1,
                }}
            >
                <Grid item xl={10} sm={9} xs={12}>
                    <InputLabel>Zaplacena záloha?</InputLabel>
                </Grid>
                <Grid item xl={2} sm={3} xs={12}>
                    <Checkbox
                        checked={checked}
                        onChange={onChangeDeposit}
                        name="deposit"
                        value={checked}

                        // error={!VALID_LONLAT.status}

                        //onChange={(e) => dispatch(onEditContract(EDITOR.ADDRESS, `${e.target.value}`))}
                        // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
                    />
                </Grid>
            </Grid>
        </>
    );
};

const ContractOperation = ({ operation, create }: { operation: IOperation; create: boolean }) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const OPERATION = operation;
    const DB_ALL_DOC_STATUSES = useSelector(
        (state: GlobalState) => state.static_db.documentStatuses
    );
    useEffect(() => {
        setSelectedStatus(`${OPERATION.status}`);
    }, [OPERATION]);
    const [SELECTED_STATUS, setSelectedStatus] = React.useState(`0`);
    const onChangeStatus = (event: SelectChangeEvent) => {
        dispatch(setContractOperationStatusAsEdited(Number(event.target.value), OPERATION.id));
        setSelectedStatus(event.target.value as string);
    };

    return (
        <>
            <Grid
                container
                spacing={1}
                style={{
                    padding: "0.5em",
                }}
            >
                <Grid
                    item
                    xl={6}
                    sm={6}
                    xs={6}
                    style={{
                        display: "flex",
                        alignItems: "flex-start",
                        justifyContent: "center",
                        flexDirection: "column",
                    }}
                >
                    <Typography>{OPERATION.name}</Typography>
                </Grid>
                <Grid item xl={6} sm={6} xs={6}>
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Úkon</InputLabel>
                        <Select
                            labelId={`select-label`}
                            label="Služba"
                            name="service"
                            value={`${SELECTED_STATUS}`}
                            onChange={onChangeStatus}
                            style={{
                                color: DB_ALL_DOC_STATUSES.clients[SELECTED_STATUS].color,
                                fontWeight: 900,
                            }}
                            // error={!VALID_NAME.status}
                            // onChange={(e) => dispatch(onEditContract(EDITOR.EMAIL, `${e.target.value}`))}
                            // helperText={!VALID_NAME.status && VALID_NAME.error}
                        >
                            {DB_ALL_DOC_STATUSES &&
                                OPERATION.supplier === "client" &&
                                DB_ALL_DOC_STATUSES.clients.map((dbStatus) => (
                                    <MenuItem
                                        style={{ color: dbStatus.color, fontWeight: 900 }}
                                        value={dbStatus.id}
                                        key={`db_service${dbStatus.id}`}
                                    >
                                        {dbStatus.name}
                                    </MenuItem>
                                ))}
                            {DB_ALL_DOC_STATUSES &&
                                OPERATION.supplier === "contractor" &&
                                DB_ALL_DOC_STATUSES.contractors.map((dbStatus) => (
                                    <MenuItem
                                        style={{ color: dbStatus.color, fontWeight: 900 }}
                                        value={dbStatus.id}
                                        key={`db_service${dbStatus.id}`}
                                    >
                                        {dbStatus.name}
                                    </MenuItem>
                                ))}
                        </Select>
                    </FormControl>
                </Grid>
            </Grid>
        </>
    );
};
