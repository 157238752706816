import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { IModalProps } from "../../../global";
import { onChangeSelected } from "../action";
import TransferList from "./TransferList";

export const AllowClientsModal = ({ open, setOpen, id }: IModalProps) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            scroll={"paper"}
            maxWidth={"xl"}
        >
            <DialogTitle id="scroll-dialog-title">
                Jaké nevlastní klienty chcete ,aby uživatel mohl spravovat:
            </DialogTitle>
            <DialogContent dividers>
                <TransferList setOpen={setOpen} id={id} />
            </DialogContent>
            <DialogActions></DialogActions>
        </Dialog>
    );
};
