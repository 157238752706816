import { Save, PersonAddAlt1 } from "@mui/icons-material";
import { Box, Button, Card, CardContent, Grid, TextField, Typography } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React, { FormEvent, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { AnyAction } from "redux";
import { editDataObjects, postDataObjects } from "../../../../API";
import { GlobalState } from "../../../../global";
import { changePage } from "../../../actions";
import {
    onEditObject,
    resetObjectEditor,
    resetObjectOwnerEditor,
    setClientToOwnerEditor,
    setObjectAsEdited,
    setObjectAsEditedCreate,
} from "../../actions";
import { OBJECT_EDITOR } from "../../constants";
import { getObjectById } from "../../selector";
import { ListOfOwners } from "./ListOfOwners";

const CreateUpdateObject = () => {
    const params = useParams();
    const navigate = useNavigate();
    const objects = useSelector((state: GlobalState) => state.api.objects);
    const object = useSelector((state: GlobalState) => state.object.editor);
    const index = objects.findIndex((item) => item.id === Number(params.id));
    const CLIENT = useSelector((state: GlobalState) => state.client.editor);
    const ONE_OBJECT = useSelector(getObjectById(index));
    const OWNER = { ...ONE_OBJECT, client_id: CLIENT.id };
    const location = useLocation();
    const USER = useSelector((state: GlobalState) => state.logged.user);
    const create = location.pathname === "/objekty/vytvorit";

    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    useEffect(() => {
        if (create) {
            dispatch(resetObjectEditor());
            dispatch(
                setObjectAsEditedCreate({
                    id: null,
                    user_id: USER.id,
                    client_id: Number(CLIENT.id),
                    address: "",
                    city: "",
                    zipcode: null,
                    owners: [
                        {
                            id: 0,
                            email: CLIENT.email,
                            name: CLIENT.name,
                            surname: CLIENT.surname,
                            phone: CLIENT.phone,
                            address: CLIENT.address,
                            city: CLIENT.city,
                            zipcode: CLIENT.zipcode,
                        },
                    ],
                    cadastral_territory: "",
                    number_cadastral_territory: "",
                    parcel_number: "",
                })
            );
            // dispatch(
            //     changePage({
            //         name: "Vytvořit objekt",
            //         path: location.pathname,
            //         tree: [{ name: "Objekty", path: "/objekty/vytvorit" }],
            //     })
            // );
        } else {
            dispatch(onEditObject(OBJECT_EDITOR.CLIENT_ID, CLIENT.id));
            dispatch(setObjectAsEdited(Number(params.id)));
            // dispatch(
            //     changePage({
            //         name: `Upravit objekt ${params.id}`,
            //         path: location.pathname,
            //         tree: [
            //             { name: "Objekty", path: "/klineti" },
            //             { name: params.id, path: `/objekty/${params.id}` },
            //         ],
            //     })
            // );
        }
    }, []);

    const handleSubmit = (event: FormEvent) => {
        event.preventDefault();
        if (create) {
            dispatch(postDataObjects());
            dispatch(resetObjectEditor());
        } else {
            dispatch(editDataObjects(object, object.id));
        }
        navigate(`/klienti/${CLIENT.id}`);
    };
    const [openOwnerModal, setOpenOwnerModal] = React.useState(false);
    return (
        <>
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <Typography component="div" variant="h5" paddingBottom={"1rem"}>
                    {create
                        ? `Přidat budovu pro vlastníka: ${CLIENT.name} ${CLIENT.surname}`
                        : `Upravit budovu vlastníka: ${CLIENT.name} ${CLIENT.surname}`}
                </Typography>
                <Box component={"form"} onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        <Grid item lg={12} md={12} sm={12} paddingBottom={"1rem"}>
                            <Card>
                                <CardContent>
                                    <Grid container spacing={2}>
                                        <Grid item sm={12} lg={6}>
                                            <ObjectAddress />
                                            <ObjectCity />
                                            <ObjectZipcode />
                                            <ObjectCadastralTerritory />
                                            <ObjectNumberCadastralTerritory />
                                            <ObjectParcelNumber />
                                        </Grid>
                                        <Grid item sm={12} lg={6}>
                                            Mapa
                                        </Grid>
                                        <Grid item sm={12} lg={12}>
                                            <ListOfOwners
                                                object={object}
                                                openOwnerModal={openOwnerModal}
                                                setOpenOwnerModal={setOpenOwnerModal}
                                            />
                                        </Grid>
                                        <Grid item sm={12}>
                                            <Box
                                                display={"flex"}
                                                justifyContent="flex-end"
                                                gap={"0.5rem"}
                                            >
                                                <Button
                                                    variant="contained"
                                                    startIcon={<PersonAddAlt1 />}
                                                    onClick={() => {
                                                        dispatch(resetObjectOwnerEditor());
                                                        setOpenOwnerModal(!openOwnerModal);
                                                    }}
                                                    //disabled={denyButton}
                                                >
                                                    DALŠÍ MAJITELE
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    variant="contained"
                                                    // startIcon={<Save />}
                                                    //disabled={denyButton}
                                                >
                                                    ULOŽIT
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Box>
            </Box>
        </>
    );
};

const ObjectAddress = () => {
    const ADDRESS = useSelector((state: GlobalState) => state.object.editor.address);

    // const VALID_LONLAT = useSelector(getValidTurnout(EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Adresa"
            name="address"
            autoComplete="street-address"
            value={ADDRESS}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditObject(OBJECT_EDITOR.ADDRESS, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const ObjectCity = () => {
    const CITY = useSelector((state: GlobalState) => state.object.editor.city);

    // const VALID_LONLAT = useSelector(getValidTurnout(OBJECT_EDITOR.LONLAT));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Město"
            name="city"
            autoComplete="address-level2"
            value={CITY}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_LONLAT.status}

            onChange={(e) => dispatch(onEditObject(OBJECT_EDITOR.CITY, `${e.target.value}`))}
            // helperText={!VALID_LONLAT.status && VALID_LONLAT.error}
        />
    );
};

const ObjectZipcode = () => {
    const ZIPCODE = useSelector((state: GlobalState) => state.object.editor.zipcode);

    // const VALID_NAME = useSelector(getValidTurnout(OBJECT_EDITOR.NAME));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="PSČ bez mezer - např.: 10400 "
            name="zipcode"
            autoComplete="postal-code"
            value={ZIPCODE}
            inputProps={{ inputMode: "numeric", pattern: "[0-9]*" }}
            sx={{ mb: 2 }}
            fullWidth
            // error={!VALID_NAME.status}
            onChange={(e) => dispatch(onEditObject(OBJECT_EDITOR.ZIPCODE, `${e.target.value}`))}
            // helperText={!VALID_NAME.status && VALID_NAME.error}
        />
    );
};

// Cadastral

const ObjectCadastralTerritory = () => {
    const CADASTRAL_TERRITORY = useSelector(
        (state: GlobalState) => state.object.editor.cadastral_territory
    );

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Katastrální uzemí"
            name="cadastral_territory"
            value={CADASTRAL_TERRITORY}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) =>
                dispatch(onEditObject(OBJECT_EDITOR.CADASTRAL_TERRITORY, `${e.target.value}`))
            }
        />
    );
};

const ObjectNumberCadastralTerritory = () => {
    const NUMBER_CADASTRAL_TERRITORY = useSelector(
        (state: GlobalState) => state.object.editor.number_cadastral_territory
    );

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Číslo katastrálního uzemí"
            name="number_cadastral_territory"
            value={NUMBER_CADASTRAL_TERRITORY}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) =>
                dispatch(
                    onEditObject(OBJECT_EDITOR.NUMBER_CADASTRAL_TERRITORY, `${e.target.value}`)
                )
            }
        />
    );
};

const ObjectParcelNumber = () => {
    const PARCEL_NUMBER = useSelector((state: GlobalState) => state.object.editor.parcel_number);

    // const VALID_ADDRESS = useSelector(getValidTurnout(EDITOR.ADDRESS));
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    return (
        <TextField
            label="Parcelní číslo"
            name="parcel_number"
            value={PARCEL_NUMBER}
            sx={{ mb: 2 }}
            fullWidth
            onChange={(e) =>
                dispatch(onEditObject(OBJECT_EDITOR.PARCEL_NUMBER, `${e.target.value}`))
            }
        />
    );
};
export default CreateUpdateObject;
