import { Add } from "@mui/icons-material";
import { Box, Button, ButtonGroup } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { Link } from "react-router-dom";
import { locale, DATA_GRID_DISABLE_OUTLINED_CELL, DELETE_MODAL } from "../../../layout/constants";
import { COLUMNS } from "../constants";
import { IClientRow } from "../types";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { GlobalState } from "../../../global";
import { changePage } from "../../actions";
import { DeleteModal } from "../../../layout/components/DeleteModal";
import {  getUsersForOwner } from "../selector";

const Clients = () => {
    const [pageSize, setPageSize] = useState(5);
    const [rows, setRows] = useState<IClientRow[]>();

    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const clients = useSelector((state: GlobalState) => state.api.clients) || [];
    const ALL_USERS = useSelector(getUsersForOwner());
    useEffect(() => {
        dispatch(
            changePage({
                name: "Klienti",
                path: "/klienti",
                tree: [{ name: "Klienti" }],
            })
        );

        const clientRows: IClientRow[] = clients.map((client, i) => {
            const userOwner = ALL_USERS.find((user) => user.id === client.user_id)?.name || '';
            const row: IClientRow = {
                ...client,
                button: (
                    <ButtonGroup>
                        <Button to={`/klienti/${client.id}`} component={Link} variant="contained">
                            Detail
                        </Button>

                        <Button
                            to={`/klienti/`}
                            onClick={() => handleDelete(client.id)}
                            component={Link}
                            color="error"
                        >
                            Smazat
                        </Button>
                    </ButtonGroup>
                ),
                userOwner: userOwner
            };
            return row;
        });
        setRows(clientRows);
    }, [clients]);

    const [open, setOpen] = React.useState(false);
    const [clientID, setID] = React.useState(null);
    const handleDelete = (id: number) => {
        setID(id);
        setOpen(!open);
    };

    return (
        <>
            <DeleteModal open={open} setOpen={setOpen} id={clientID} item={DELETE_MODAL.CLIENT} />
            <Box component="main" sx={{ flex: 1, py: 6, px: 4, bgcolor: "#eaeff1" }}>
                <Box justifyContent={"flex-end"} display="flex" paddingBottom={"1rem"}>
                    <Button
                        startIcon={<Add />}
                        variant="contained"
                        to="/klienti/vytvorit"
                        component={Link}
                    >
                        Přidat klienta
                    </Button>
                </Box>
                <Box style={{ width: "100%" }} paddingBottom={"1rem"}>
                    {rows && (
                        <DataGrid
                            sx={DATA_GRID_DISABLE_OUTLINED_CELL}
                            disableSelectionOnClick
                            hideFooterSelectedRowCount
                            style={{ backgroundColor: "#FFF" }}
                            columns={COLUMNS}
                            rows={rows}
                            pageSize={pageSize}
                            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                            rowsPerPageOptions={[5, 10, 25, 50]}
                            autoHeight
                            localeText={locale}
                        />
                    )}
                </Box>
            </Box>
        </>
    );
};

export default Clients;
