import { Dispatch } from "redux";
import { GlobalState } from "../global";
import { IClient, IContract, IFile } from "../pages/Clients/types";
import { ICreateUser } from "../pages/Users/types";
import * as constants from "./constants";
import { IApiState, IStatic_dbState, IObject } from "./types";
export const storeInitState = (init: IStatic_dbState) => ({
  init,
  type: constants.INIT_DATA_STORE,
});

export const storeInitApiState = (initApi: IApiState) => ({
  initApi,
  type: constants.INIT_API_DATA_STORE,
});

export const deleteClient = (clientId: number) => ({
  clientId,
  type: constants.DELETE_CLIENT,
});

export const onSaveClient = (client: IClient, clientId: number = null) => ({
  client,
  clientId,
  type: constants.SAVE_CLIENT,
});

export const deleteContract = (contractId: number) => ({
  contractId,
  type: constants.DELETE_CONTRACT,
});

export const onSaveContract = (
  contract: IContract,
  contractId: number = null
) => ({
  contract,
  contractId,
  type: constants.SAVE_CONTRACT,
});

export const deleteObject = (objectId: number) => ({
  objectId,
  type: constants.DELETE_OBJECT,
});

export const onSaveObject = (object: IObject, objectId: number = null) => ({
  object,
  objectId,
  type: constants.SAVE_OBJECT,
});

export const deleteNote = (noteId: number) => ({
  noteId,
  type: constants.DELETE_NOTE,
});

export const onSaveNote = (note: IClient, noteId: number = null) => ({
  note,
  noteId,
  type: constants.SAVE_NOTE,
});

export const deleteUser = (userId: number) => ({
  userId,
  type: constants.DELETE_USER,
});

export const onSaveUser = (user: ICreateUser, userId: number = null) => ({
  user,
  userId,
  type: constants.SAVE_USER,
});