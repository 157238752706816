import { Edit, ExpandMore } from "@mui/icons-material";
import {
    Accordion,
    AccordionSummary,
    Typography,
    AccordionDetails,
    Box,
    Button,
} from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { AnyAction } from "redux";
import { delDataContracts } from "../../../../API";
import { GlobalState } from "../../../../global";
import { DeleteModal } from "../../../../layout/components/DeleteModal";
import { DELETE_MODAL } from "../../../../layout/constants";
import { getOneBuildingById } from "../../selector";
import { IContract } from "../../types";
import { OperationItem } from "./OperationItem";

export const ContractDetail = ({ contract, index }: { contract: IContract; index: number }) => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const services = useSelector((state: GlobalState) => state.static_db.services);
    const selectedService = (id: number) => {
        const res = services.find((ser) => ser.id === Number(id));
        return res;
    };
    const service = selectedService(Number(contract.service));
    const BUILDING = useSelector(getOneBuildingById(Number(contract.building)));
    const USER = useSelector((state: GlobalState) => state.logged.user);

    const formatter = new Intl.NumberFormat("cs-CZ", {
        style: "currency",
        currency: "CZK",
    });

    const [open, setOpen] = React.useState(false);
    const handleDeleteContract = () => {
        setOpen(!open);
    };

    return (
        <>
            <DeleteModal
                open={open}
                setOpen={setOpen}
                id={contract.id}
                item={DELETE_MODAL.CONTRACT}
            />

            <Accordion disableGutters>
                <AccordionSummary
                    expandIcon={<ExpandMore />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                >
                    <Typography sx={{ width: "33%", flexShrink: 0 }}>
                        {BUILDING && index + 1 + `. ${BUILDING.address} | ${BUILDING.city}`}
                    </Typography>
                    <Typography sx={{ color: "text.secondary" }}>{service.name}</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Typography style={{ padding: "0.5em" }} variant="h5">
                        {BUILDING && ` ${BUILDING.address} | ${BUILDING.city}`}
                    </Typography>
                    {USER.role == 0 ? (
                        <Typography style={{ padding: "0.5em" }} variant="h6">
                            Domluvená cena: {formatter.format(contract.agreedPrice)}
                        </Typography>
                    ) : (
                        ``
                    )}
                    <Typography style={{ padding: "0.5em" }} variant="h6">
                        Záloha: {contract.deposit === true ? "Ano" : "Ne"}
                    </Typography>
                    <Typography style={{ padding: "0.5em" }}>Klient:</Typography>
                    {contract.operation.map((opera, i) => {
                        if (opera.supplier === "client")
                            return (
                                <OperationItem
                                    key={`operation_${i}`}
                                    operation={opera}
                                    contract={contract}
                                />
                            );
                    })}
                    <Typography style={{ padding: "0.5em" }}>Zhotovitel:</Typography>
                    {contract.operation.map((opera, i) => {
                        if (opera.supplier === "contractor")
                            return (
                                <OperationItem
                                    key={`operation_${i}`}
                                    operation={opera}
                                    contract={contract}
                                />
                            );
                    })}
                    <Box justifyContent={"flex-end"} display="flex" paddingBottom={"1rem"}>
                        <Button
                            variant="outlined"
                            color="error"
                            onClick={handleDeleteContract}
                            style={{ marginRight: "1em" }}
                        >
                            Smazat
                        </Button>
                        <Button
                            startIcon={<Edit />}
                            variant="contained"
                            to={`/zakazky/${contract.id}/editovat`}
                            component={Link}
                        >
                            Upravit zakázku
                        </Button>
                    </Box>
                </AccordionDetails>
            </Accordion>
        </>
    );
};
