import React from "react";
import { ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { Link } from "react-router-dom";

import { IMenuItem } from "./menuTypes";

const MenuItemLink = (props: IMenuItem) => {
    const { icon, title, route } = props;
    const item = {
        py: "2px",
        px: 3,
        color: "rgba(255, 255, 255, 0.7)",
        "&:hover, &:focus": {
            bgcolor: "rgba(255, 255, 255, 0.08)",
        },
        cursor: "pointer",
    };

    const itemCategory = {
        boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
        py: 1.5,
        px: 3,
    };
    return (
        <>
            <Link to={route}>
                <ListItem sx={{ ...item, ...itemCategory }}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText primary={title} />
                </ListItem>
            </Link>
        </>
    );
};

export default MenuItemLink;
