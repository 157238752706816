import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from "@mui/material";
import { ThunkDispatch } from "@reduxjs/toolkit";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import { GlobalState, IModalProps } from "../../../../global";
import { resetObjectOwnerEditor, setOwnerToObjectEditor } from "../../actions";
import CreateUpdateOwner from "./CreateUpdateOwner";

export const OwnerModal = ({ open, setOpen }: IModalProps) => {
    //const HIGHCHARTS_OPTIONS = useSelector(getHighchartsOptions);

    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();
    const EDITED_ID = useSelector((state: GlobalState) => state.object.owner_editor.id);

    return (
        <Dialog
            open={open}
            onClose={() => setOpen(!open)}
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            scroll={"paper"}
            maxWidth={"xl"}
        >
            <DialogTitle id="scroll-dialog-title">Editor</DialogTitle>
            <DialogContent dividers>
                <CreateUpdateOwner />
            </DialogContent>
            <DialogActions>
                <Button
                    variant="outlined"
                    color="primary"
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                        setOpen(!open);
                    }}
                >
                    Zahodit změny
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    sx={{ ml: "0.5rem" }}
                    onClick={() => {
                        dispatch(setOwnerToObjectEditor(EDITED_ID));

                        setOpen(!open);
                    }}
                >
                    OK
                </Button>
            </DialogActions>
        </Dialog>
    );
};
