import React from "react";
import { IOperation } from "../../../../database/types";
import { Check, PhoneInTalk, PhoneDisabled, Close, Handyman } from "@mui/icons-material/";
export const OperationItemIcon = ({ operation }: { operation: IOperation }) => {
    const ICONS = [
        { status: 0, component: <PhoneInTalk />, supplier: "client", color: "initial" },
        { status: 1, component: <PhoneDisabled />, supplier: "client", color: "#ff9800" },
        { status: 2, component: <Check />, supplier: "client", color: "#28a745" },
        { status: 3, component: <Close />, supplier: "client", color: "#f44336" },

        { status: 0, component: <Close />, supplier: "contractor", color: "initial" },
        { status: 1, component: <Handyman />, supplier: "contractor", color: "#ff9800" },
        { status: 2, component: <Check />, supplier: "contractor", color: "#28a745" },
    ];
    return (
        <>
            {ICONS.map((icon, i) => {
                if (icon.supplier === operation.supplier && icon.status === operation.status)
                    return (
                        <div key={i} style={{ color: icon.color }}>
                            {icon.component}
                        </div>
                    );
                return <div key={i}></div>;
            })}
        </>
    );
};
