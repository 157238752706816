import { Dispatch } from "redux";
import { GlobalState } from "../../global";
import { CHANGE_SELECTED, CREATE_USER_EDITOR, EDIT_USER, LOGOUT_USER, RESET_USER_EDITOR, SET_USER_AS_EDITED, SET_USER_AS_LOGGED } from "./constants";
import { IUser, IUserState } from "./types";

export const setUserAsLogged = (user: IUserState) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        dispatch({
            user,
            type: SET_USER_AS_LOGGED,
        });
    };

export const setUserAsEdited = (id: number) =>
    (dispatch: Dispatch, getState: () => GlobalState) => {
        const user = getState().api.users.find(user => user.id === id);
        dispatch({
            user,
            type: SET_USER_AS_EDITED,
        });
    };

export const onEditUser = (usrKey: CREATE_USER_EDITOR, value: string | boolean | number) => ({
    usrKey,
    value,
    type: EDIT_USER,
});
export const resetUserEditor = () => (dispatch: Dispatch, getState: () => GlobalState) => {
    dispatch({
        type: RESET_USER_EDITOR,
    });
};

export const onChangeSelected = (selected: number[]) => ({
    selected,
    type: CHANGE_SELECTED,
});