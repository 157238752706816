
import * as constants from "./constants";
import { IUserState } from "../Users/types";
import { AppAction, GlobalState } from "../../global";
import { IClientState, IFileState, IObjectState } from "./types";
import { IContractState } from "./types";
import { useSelector } from "react-redux";
import { Dispatch } from "@reduxjs/toolkit";


const defaultState: IClientState = {

    editor: {
        id: null,
        user_id: null,
        email: "",
        name: "",
        surname: "",
        phone: "",
        address: "",
        city: "",
        zipcode: null,
        add_building: true,
        cadastral_territory: "",
        number_cadastral_territory: "",
        parcel_number: "",
    },
    options: {
        add_building: true,
        note_editor: {
            id: null,
            user_id: null,
            client_id: null,
            created_at: null,
            updated_at: null,
            note_content: "",
            note_headline: ""
        }
    }
};

export const clientReducer = (state: IClientState = defaultState, action: AppAction) => {
    switch (action.type) {
        case constants.SET_NOTE_AS_EDITED:
            return {
                ...state,
                options: {
                    ...state.options,
                    note_editor: action.note
                },
            };
        case constants.EDIT_NOTE:
            return {
                ...state,
                options: {
                    ...state.options,
                    note_editor: {
                        ...state.options.note_editor,
                        [action.noteKey]: action.value
                    }
                },
            };
        case constants.RESET_NOTE_EDITOR:
            return {
                ...state,
                options: {
                    ...state.options,
                    note_editor: {
                        ...defaultState.options.note_editor,
                        user_id: Number(action.user.id),
                        client_id: Number(action.clientId)
                    }
                },
            };


        case constants.OPTIONS_SET_ADD_BUILDING:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    add_building: action.checked
                }
            }


        case constants.SET_CLIENT_AS_EDITED:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    ...action.client
                }
            }

        case constants.EDIT_CLIENT:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.key]: action.value,
                },
            };
        case constants.RESET_EDITOR:
            return {
                ...state,
                editor: {
                    ...defaultState.editor,
                    user_id: Number(action.user.id)
                },
            };
        default:
            return state;
    }
}
const defaultStateContract: IContractState = {
    editor: {
        id: null,
        building: null,
        client_id: null,
        user_id: null,
        service: null,
        operation: [],
        agreedPrice: null,
        deposit: false,
    }
};
export const contractReducer = (state: IContractState = defaultStateContract, action: AppAction) => {
    switch (action.type) {
        case constants.SET_CONTRACT_AS_EDITED:
            return {
                ...state,
                editor: action.contract
            }
        case constants.SET_CONTRACT_AS_EDITED_CREATE:
            return {
                ...state,
                editor: action.contract
            }
        case constants.SET_CONTRACT_OPERATION_AS_EDITED:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    operation: action.operation
                }
            }
        case constants.SET_CONTRACT_OPERATION_STATUS_AS_EDITED:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    operation: state.editor.operation.map(
                        (operation) => operation.id === action.operationId
                            ? { ...operation, status: action.status }
                            : operation
                    )
                }
            }
        case constants.EDIT_CONTRACT:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.conKey]: action.value,
                },
            };
        case constants.RESET_CONTRACT_EDITOR:
            return {
                ...state,
                editor: {
                    ...defaultStateContract.editor,
                },
            };
        default:
            return state;
    }
}
const defaultStateObject: IObjectState = {
    editor: {
        id: 0,
        client_id: null,
        user_id: null,
        address: "",
        city: "",
        zipcode: null,
        owners: [],
        cadastral_territory: "",
        number_cadastral_territory: "",
        parcel_number: "",
    },
    owner_editor: {
        id: null,
        email: "",
        name: "",
        surname: "",
        phone: "",
        address: "",
        city: "",
        zipcode: null
    }
};
export const objectReducer = (state: IObjectState = defaultStateObject, action: AppAction) => {
    switch (action.type) {
        case constants.SET_OBJECT_AS_EDITED:
            return {
                ...state,
                editor: action.object
            };
        case constants.SET_OBJECT_AS_EDITED_CREATE:
            return {
                ...state,
                editor: action.object
            };
        case constants.EDIT_OBJECT:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.objKey]: action.value,
                },
            };
        case constants.RESET_OBJECT_EDITOR:
            return {
                ...state,
                editor: {
                    ...defaultStateObject.editor,
                },
            };
        case constants.SET_CLIENT_TO_OWNER_EDITOR:
            return {
                ...state,
                owner_editor: { ...state.editor.owners[action.ownerId] }
            };
        case constants.RESET_OBJECT_OWNER_EDITOR:
            return {
                ...state,
                owner_editor: {
                    ...defaultStateObject.owner_editor,
                }
            };
        case constants.SET_OWNER_TO_OBJECT_EDITOR:
            if (state.owner_editor.id === null) {
                state.editor.owners.push({ ...state.owner_editor, id: state.editor.owners.length })
                return {
                    ...state
                }
            } else {
                return {
                    ...state,
                    editor: {
                        ...state.editor,
                        owners: state.editor.owners.map(
                            (owner, id) => (action.ownerId !== id ? { ...owner } : state.owner_editor))
                    }
                };
            }
        case constants.EDIT_OWNER_CLIENT:
            return {
                ...state,
                owner_editor: {
                    ...state.owner_editor,
                    [action.ownKey]: action.value,
                },
            };
        case constants.REMOVE_OWNER_CLIENT:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    owners: state.editor.owners
                        .filter((owner) => owner.id !== action.ownerId)
                }
            }
        default:
            return state;
    }
}
const defaultStateFile: IFileState = {
    editor: {
        id: null,
        user_id: null,
        client_id: null,
        created_at: null,
        updated_at: null,
        name: "",
        path: "",
        directory: ""
    }
};
export const fileReducer = (state: IFileState = defaultStateFile, action: AppAction) => {
    switch (action.type) {
        case constants.SET_FILE_AS_EDITED:
            return {
                ...state,
                editor: action.file

            };
        case constants.EDIT_FILE:
            return {
                ...state,
                editor: {
                    ...state.editor,
                    [action.fileKey]: action.value
                }
            };
        case constants.RESET_FILE_EDITOR:
            return {
                ...state,
                editor: {
                    ...defaultStateFile.editor,
                    user_id: Number(action.user.id),
                    client_id: Number(action.clientId)
                }
            };
        default:
            return state;
    }
}