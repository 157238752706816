import { ThunkDispatch } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AnyAction } from "redux";
import "./App.css";
import Paperbase from "./layout/Paperbase";

import { getInitApiState, getInitStatic_dbState } from "./API";
import React from "react";
import { Login } from "./pages/Users/components/Login";
import { GlobalState } from "./global";

export const App = () => {
    const dispatch = useDispatch<ThunkDispatch<{}, {}, AnyAction>>();

    const USER_STATE = useSelector((state: GlobalState) => state.logged.user);

    if (!(sessionStorage.token === USER_STATE.token)) {
        return (
            <>
                <Login />
            </>
        );
    } else {
        dispatch(getInitStatic_dbState());
        dispatch(getInitApiState());
        return (
            <>
                <Paperbase />
            </>
        );
    }
};
